import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { EmploymentConfig } from '../employment.config';
import { Store } from '@ngrx/store';
import * as ACTIONS_INTERFACE from '../../actions';
import { BehaviorSubject } from 'rxjs';
import { EmploymentService } from '../employment.service';

import { EventBusService } from 'framework/eventbus.service';
import { UtilService } from 'framework/utils/util.service';

import * as dateUtils from 'framework/utils/date.utils';

declare var google: any;
declare var jQuery: any;

@Component({
  selector: 'position',
  templateUrl: './position.component.html',
  styleUrls: ['./position.component.css']
})
export class PositionComponent implements OnInit {

  _currentRowData = new BehaviorSubject<any>([]);
  @Input('group')
  public empSubForm: FormGroup;

  @Input() row: any;
  @Input() userDetails: any;
  @Input() empType: string;
  @Input() configOptions: any;
  @Input() clipboardOptions: any;
  @Input() isReviewAdd: boolean;
  @Input() isReviewEdit: boolean;
  @Input() options: any;
  @Input() activeGapOption: any;
  @Input() dateOptions: Object;
  @Input() positionchange: number;
  @Input() addMorePositions: any;
  @Input() showEndDate: any;
  @Input() isEmploymentGapInDaysEnabled: boolean;

  @Input()
  set currentRowPositionList(value) {
    this._currentRowData.next(value);
  };

  get currentRowPositionList() {
    return this._currentRowData.getValue();
  }

  @Output() onGoToProfileBuilder: EventEmitter<any> = new EventEmitter();
  @Output() onAddEmpPosition: EventEmitter<any> = new EventEmitter();
  @Output() onAddEmpPositionSwipe: EventEmitter<any> = new EventEmitter();
  @Output() onSkipEmpPosition: EventEmitter<any> = new EventEmitter();
  @Output() onEditRecord: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteRecord: EventEmitter<any> = new EventEmitter();
  @Output() onSaveAndExitRecord: EventEmitter<any> = new EventEmitter();

  isViewInit;

  static STAGE_ID: string;
  static STEP_ID: string;
  static STEP_INDEX: number;
  public SUB_STEP_ID: string;
  public SUB_STEP_INDEX: number;
  enableNextbtn: boolean = false;
  PARENT_STAGE_STEP_CONFIG: Object;

  static BUTTON_CLICKED: string = 'CLICKED_SAVE_EXIT_BUTTON';
  currentStatus: string = 'VISITED';
  payLoad: Object;
  startTime: any;
  isReview: boolean = false;
  isValidEmpPos: boolean = false;
  multiPositionObj: any = {};
  fromDateOptions: Object;
  toDateOptions: Object;
  fromMinDate: Date;
  fromMaxDate: Date;
  fromDefaultDate: Date;
  toMinDate: Date;
  toMaxDate: Date;
  toDefaultDate: Date;
  toSetDate: Date;
  fromSetDate: Date;
  intlStartDateId: any;
  intlEndDateId: any;
  isGapExist: boolean = false;
  positionGapDetails: Object = {};
  rowLen: Number = 0;
  isOverlapExist: boolean = false;
  IsFutureDate: boolean = false;
  isValidPriorPosionEndDate: boolean = true;
  invalidPositionTitle: string = '';

  constructor(private _store: Store<ACTIONS_INTERFACE.AppState>,
    private _eventBus: EventBusService, // Auto Generated PPA-8745 - Arun Supreet for Save n Exit Patch
    private _es: EmploymentService,
    public _util: UtilService
  ) {
    this.startTime = new Date().getTime();
  }

  ngOnInit(): void {
    this.isViewInit = false;
    if (!!this.isReviewAdd || !!this.isReviewEdit) {
      this.isReview = true;
    }

    this.intlStartDateId = `jobFrom_${this.row}`;
    this.intlEndDateId = `jobTo_${this.row}`;


    this._currentRowData
      .subscribe(res => {
        if (!!this.currentRowPositionList && this.currentRowPositionList.length > 1) {
          this.currentRowPositionList.sort((a, b) => {
            return this._es.getDateToTs(b['intlStartDate']) - this._es.getDateToTs(a['intlStartDate']);
          });
        }
        this.addMorePositions = false;
        if (!this._util.isEmpty(res)) {
          if (!this._util.isEmpty(res) && res.length > 0) {
            this.addMorePositions = true;
          }
        }
      });
    this.ValidatePositionListData(this.currentRowPositionList);
  }

  ngAfterViewInit(): void {

    this.processConfig();
    this.isViewInit = true;
    this.setEmpTypeControl();
    this.validateEmpPosition(this.empSubForm.value);
    this.ValidatePositionListData(this.currentRowPositionList);
  }

  ngOnChanges() {
    if (this.isViewInit && this.empType === 'prior') {
      this.setEmpTypeControl();
    }
    this.IsFutureDate = false;
    this._currentRowData
      .subscribe(res => {
        if (!!this.currentRowPositionList) {
          if (this.rowLen !== this.currentRowPositionList.length) {
            this.isGapExist = false;
            this.isOverlapExist = false;
          }
          this.rowLen = this.currentRowPositionList.length;
          if (this.currentRowPositionList.length > 1) {
            this.currentRowPositionList.sort((a, b) => {
              return this._es.getDateToTs(b['intlStartDate']) - this._es.getDateToTs(a['intlStartDate']);
            });
          }
        } else {
          this.isGapExist = false;
          this.isOverlapExist = false;
        }

        if (!this._util.isEmpty(res)) {
          this.addMorePositions = false;
          if (!this._util.isEmpty(res) && res.length > 0) {
            this.addMorePositions = true;
          }
        }
      });
    this.ValidatePositionListData(this.currentRowPositionList);
  }

  doSwipe(direction: string, data) {
    // alert(direction);
    if (direction === 'swiperight') {
      this.getPrevSwipe(data);
    }
    if (direction === 'swipeleft') {
      if (!!data['managerName']) {
        this.addEmpPosition(data);
      }

    }
  }

  processConfig(): void {

    this.PARENT_STAGE_STEP_CONFIG = this.configOptions['PARENT_CONFIG'];
    this.SUB_STEP_ID = EmploymentConfig.subStepIndexMapping['emp_position']['subStep'];
    this.SUB_STEP_INDEX = EmploymentConfig.subStepIndexMapping['emp_position']['subStepIndex'];

    this.setState();
  }

  setEmpTypeControl() {
    (<FormControl>this.empSubForm.controls['type']).setValue(this.empType);
  }

  setState(): void {

    this.payLoad = {
      name: this.PARENT_STAGE_STEP_CONFIG['STAGE_ID'],
      step: this.PARENT_STAGE_STEP_CONFIG['STEP_ID'],
      stepIndex: this.PARENT_STAGE_STEP_CONFIG['STEP_INDEX'],
      subStep: this.SUB_STEP_ID,
      subStepIndex: this.SUB_STEP_INDEX,
      iteration: this.row,
      status: this.currentStatus,
      dbRecordPresent: this.PARENT_STAGE_STEP_CONFIG['IS_DB_RECORD_PRESENT']
    };
    this._store.dispatch({ type: this.currentStatus, payload: this.payLoad });
  }


  getPrevSwipe(data) {
    let indexDetail: Object = {};
    indexDetail['row'] = this.row;
    data['indexDetail'] = indexDetail;
    this.onAddEmpPositionSwipe.emit(data);
  }


  saveExit(obj) {
    // PPA-8745 - Auto-generated via regex - Arun, Supreet, Save n Exit Patch
    let re = this._eventBus.logoutAnnounced({ dummy: 'workflow_exit' });
    if (re === undefined) { return; }
  }

  
  addEmpPosition(obj): void {
    if (this.isGapExist) {
      obj['posLevelGapExist'] = true;
      obj['posLevelGapDetails'] = this.positionGapDetails;
    }

    /*
*
* User has submitted the form using 'NEXT' button, then current status should be changed to 'COMPLETED'.
* If user has submitted the form using 'SAVE & EXIT' button, the current status has already been
* changed to 'PENDING' in saveExit()
*
* */
    if (this.currentStatus === 'VISITED') {
      this.currentStatus = 'COMPLETED'
    }

    // Update the state
    this.setState();


    let indexDetail: Object = {};
    indexDetail['row'] = this.row;
    obj['indexDetail'] = indexDetail;
    // pass the state to server
    obj['COMPONENT_STATE'] = this.payLoad;
    obj['startTime'] = this.startTime;
    this.multiPositionObj = obj;
    this.onAddEmpPosition.emit(obj);

  }

  goToProfileBuilder() {
    this.onGoToProfileBuilder.emit();
  }

  
  skipEmpPosition(obj) {

    if (this.currentStatus === 'VISITED') {
      this.currentStatus = 'COMPLETED'
    }
    // Update the state
    this.setState();


    let indexDetail: Object = {};
    indexDetail['row'] = this.row;
    obj['indexDetail'] = indexDetail;
    // pass the state to server
    obj['COMPONENT_STATE'] = this.payLoad;
    obj['startTime'] = this.startTime;
    this.validateGap();
    this.validateFutureDate();
    // do not move forward if any gaps found within 2 two positions
    if (!this.isGapExist && !this.isOverlapExist && !this.IsFutureDate && this.isValidPriorPosionEndDate) {
      this.onSkipEmpPosition.emit(obj);
    }
  }

  /**
   * check gaps between position.
   *
   */
  validateGap() {
    this.isGapExist = false;
    this.isOverlapExist = false;
    this.IsFutureDate = false;
    this.isValidPriorPosionEndDate = true;
    this.invalidPositionTitle = '';
    this.positionGapDetails = {};
    if (this.currentRowPositionList.length > 1) {
      let positionList = [];
      for (let position of this.currentRowPositionList) {
        if (position['status'] === true) {
          let option = {};
          option['empPosition'] = position['empPosition'];
          option['intlStartDate'] = position['intlStartDate'];
          option['intlEndDate'] = position['intlEndDate'];
          positionList.push(option);
        }
      }
      positionList.sort((a, b) => {
        return this._es.getDateToTs(a['intlStartDate']) - this._es.getDateToTs(b['intlStartDate']);
      });

      // validation to do not allow end date as null for prior employment
      if (this.empType === 'prior') {
        for (let index = 0; index < positionList.length; index++) {
          let endDate = positionList[index]['intlEndDate'];
          if (endDate === 'Present') {
            this.invalidPositionTitle = positionList[index]['empPosition'];
            this.isValidPriorPosionEndDate = false;
            return;
          }
        }
      }
      

      for (let index = 0; index < positionList.length - 1; index++) {
        let endDate = this._es.getDateToTs(positionList[index]['intlEndDate']);
        if (isNaN(endDate)) {
          let currentDate = this.getCurrentDate();
          if (currentDate !== undefined) {
            endDate = this._es.getDateToTs(currentDate);
          } 
        }
        let startDate = this._es.getDateToTs(positionList[index + 1]['intlStartDate']);
        let diff: number;
        if (this.isEmploymentGapInDaysEnabled) {
          diff = dateUtils.dateDiffInDays(endDate, startDate);
        } else {
          diff = this._es.monthDiffOfDates(endDate, startDate);
        }
        if (diff > 1) {
          this.isGapExist = true;
          this.positionGapDetails['posGapLabel1'] = positionList[index + 1]['empPosition'];
          this.positionGapDetails['posGapLabel2'] = positionList[index]['empPosition'];
          this.positionGapDetails['posGapStartDate'] = positionList[index]['intlEndDate'];
          this.positionGapDetails['posGapEndDate'] = positionList[index + 1]['intlStartDate'];
        } else if (diff < 0 ) {
          this.isOverlapExist = true;
        }
      }
    }
  }

  getFromDpValue(value): void {
    (<FormControl>this.empSubForm.controls['intlStartDate']).setValue(value);
    this._es.jobFromDateCollection[this.row] = value;
    if (!!value) {
      this.dateOptions['from'] = this._es.fromDateOptions(value);
    }
  }

  getToDpValue(value): void {
    if (this.empSubForm.controls['intlEndDate'] !== undefined) {
      (<FormControl>this.empSubForm.controls['intlEndDate']).setValue(value);
      this._es.jobToDateCollection[this.row] = value;
      if (!!value) {
        this.dateOptions['to'] = this._es.toDateOptions(value);
      }
    }
  }

  editRecord(obj, index) {
    this.isGapExist = false;
    this.isOverlapExist = false;
    let opt = {};
    opt = obj;
    opt['indexOfMultiPos'] = index;
    opt['editIndicator'] = 'edit';
    this.onEditRecord.emit(opt);
  }

  deleteRecord(obj, index) {
    this.isGapExist = false;
    this.isOverlapExist = false;
    let opt = {};
    opt = obj;
    opt['indexOfMultiPos'] = index;
    this.onDeleteRecord.emit(opt);
  }

  validateEmpPosition(value) {
    let empPosition = value['empPosition'];
    if (empPosition !== '' || empPosition !== undefined) {
      this.isValidEmpPos = true;
    } else {
      this.isValidEmpPos = false;
    }
  }
  ValidatePositionListData(val: any) {
    let falseCount = 0;
    let valLen;
    if (!!val) {
      valLen = val.length;
      for (let i = 0; i < valLen; i++) {
        if (val[i]['status'] === false || this._util.isEmpty(val[i]['empPosition']) ||
          this._util.isEmpty(val[i]['intlStartDate']) || (this.showEndDate && this._util.isEmpty(val[i]['intlEndDate'])) ||
          (this.empType !== 'current' && val[i]['intlEndDate'] === 'Present')) {
          falseCount++;
        }
      }
      if (falseCount === valLen) {
        this.enableNextbtn = false;
      } else {
        this.enableNextbtn = true;
      }
    }
  }

  private getCurrentDate(): any {
    let now: Date = new Date();
    let currentMonth: string = this._es.monthNames[now.getMonth()];
    let currentYear: number = now.getFullYear();
    if (this.isEmploymentGapInDaysEnabled) {
      return `${currentMonth} ${now.getDate()},${now.getFullYear()}`;
    } else {
      return `${currentMonth} ${now.getFullYear()}`;
    }
  }

  checkForFutureDate(date) {
    if (date !== undefined && date !== null) {
      let currentDate = this.getCurrentDate();
      if (currentDate !== undefined) {
        let currentDateInTS = this._es.getDateToTs(currentDate);
        if (date !== undefined) {
          let diff: number;
          if (this.isEmploymentGapInDaysEnabled) {
            diff = dateUtils.dateDiffInDays(currentDateInTS, date);
          } else {
            diff = this._es.monthDiffOfDates(currentDateInTS, date);
          }
          if (diff > 1) {
            this.IsFutureDate = true;
          } 
        }
      }
    }
  }

  validateFutureDate() {
    this.IsFutureDate = false;
    if (this.currentRowPositionList !== undefined && this.currentRowPositionList.length > 0) {
      for (let index = 0; index < this.currentRowPositionList.length; index++) {
        if (this.currentRowPositionList[index]['status'] === true) {
          let endDate = this.currentRowPositionList[index]['intlEndDate'];
          if (endDate === 'Present') {
            let currentDate = this.getCurrentDate();
            if (currentDate !== undefined) {
              endDate = currentDate;
            } 
          }
          let startDate = this.currentRowPositionList[index]['intlStartDate'];
          this.checkForFutureDate(startDate);
          this.checkForFutureDate(endDate);
        }
      }
    }
  }

}
