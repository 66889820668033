import { Component, ViewEncapsulation, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { DynamicFormComponent } from "framework/dynamicCard";
import { EventBusService } from "framework/eventbus.service";
import { WotcSharedService } from "framework/dynamicCard/shared/shared.service";
import { TranslateService } from "framework/i18n";
import { UtilService } from "framework/utils/util.service";
import { Store } from "@ngrx/store";
import * as ACTIONS_INTERFACE from '../../actions';
import { EmploymentService } from '../employment.service';
import { EmploymentConfig } from '../employment.config';
import { BehaviorSubject } from "rxjs";
import { DotCustomFormComponent } from "app/shared/dot/dot-customform.component";
import { DOTService } from "app/shared/dot/dot.service";
import { DOTConfig } from "app/shared/dot/dot.config";
import { SharedService } from "app/shared/services/shared.service";


@Component({
  selector: 'employment-custform',
  templateUrl: './employment-customform.component.html',
  styleUrls: ['./employment-customform.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EmploymentCustomformComponent extends DotCustomFormComponent {

  @Input('group')
  public empSubForm: FormGroup;
  @Input() row: any;
  @Input() empType: string;
  @Input() configOptions: any;
  @Input() clipboardOptions: any;
  _formDeleted = new BehaviorSubject<any>([]);

  @Output() onCustomForm: EventEmitter<any> = new EventEmitter();
  @Output() onCustomFormSwipe: EventEmitter<any> = new EventEmitter();

  PARENT_STAGE_STEP_CONFIG: Object;
  public SUB_STEP_ID: string;
  public SUB_STEP_INDEX: number;
  payLoad: Object;
  formDataChanged: boolean = false;

  @Input()
  set isFormDeleted(value) {
    this._formDeleted.next(value);
  };

  get isFormDeleted() {
    return this._formDeleted.getValue();
  }

  @ViewChild(DynamicFormComponent, { static: true }) form: DynamicFormComponent;

  constructor(
    public _ds: DOTService,
    public _es: EmploymentService,
    public _eventBus: EventBusService,
    public _wSc: WotcSharedService,
    public _ts: TranslateService,
    public _util: UtilService,
    public _store: Store<ACTIONS_INTERFACE.AppState>,
    public _sharedService: SharedService
  ) {
    super(_ds, _eventBus, _ts, _util, _store);
  }

  ngOnInit() {
    super.ngOnInit();
    console.log("EmploymentCustomFormComponent ----" + this.clipboardOptions + ":" + this.configOptions + ":" + this.empType + ":");
    //PPA-14222: Lookup the custom form variables. The values will be replaced for the custom form placeholders.
    //e.g. $customerName will be replaced by the Customer name as retrived by this service.
    // Pre-emptive call to avoid call from widget and interpolation pipe.
    this._sharedService.getInterpolationData("${customerName}");

    this._formDeleted
      .subscribe(x => {
        if (x !== undefined && x === true) {
          this.finalSourceInputs = [];
          this.prepareConfigObject();
          this.formDataChanged = true;
        }
      });
    this.formDataChanged = false;
    if (this.finalSourceInputs.length == 0) {
      this.prepareConfigObject();
    }
  }

  prepareConfigObject() {
    let config: any;
    this.counter = 0;

    if (this.empSubForm.controls['form'] && this.empSubForm.controls['form'].value && this.empSubForm.controls['form'].value != '') {
      this.customFormData = [this.empSubForm.controls['form'].value];
    } else {
      this.customFormData = this._es.getCustomFormData();
    }

    this.prepareCustomFormsInputs(this.customFormData);
    console.log(this.finalSourceInputs);

    // Step 2 -
    if (this.finalSourceInputs.length > 0) {
      config = this.prepareCardData(this.finalSourceInputs[0]);
    } else {
      config = DOTConfig.getDefaultConfig();
      config[0]['inputs'][0]['label'] = this._ts.instant('COMMONS_NEXT');
      config[0]['inputs'][1]['label'] = this._ts.instant('COMMONS_SAVE_AND_EXIT');
      config[0]['header'] = this._ts.instant('EMPLOYMENT_STD_EXT_FORM');;
    }
    this.addCSSToButtons(config);
    config.forEach((item, key) => {
      this._wSc.componentConfigChanges(config, item, key);
    });
    this.config = config;
  }

  prepareCardData(data: any): any {
    let _data = data;
    let finalData: any = [];
    let headersSubHeaders = EmploymentConfig.getHeaderSubheaders();

    let i = this.counter;

    // Contains ListOfinputs array of control objects
    let tempInput;

    // Contains Header and Subheader info
    let tempInfo;

    // Button List
    let buttonInputs;

    let processedData: any;
    let headerTxt: string;
    let subHeaderTxt: string = '';
    let section;
    let showInstruction: boolean = true;
    let acicSign: boolean = false;
    acicSign = this.getAcicSignature(_data);
    // Decide tempInput data
    tempInput = this.getTempInput(_data);
    // Prepare Header
    headerTxt = this.prepareHeaderText(_data, headersSubHeaders);
    // headerTxt = (_data['header'] !== undefined) ? _data['header'] : headersSubHeaders[0]['header'];
    subHeaderTxt = (_data['subheader'] !== undefined) ? _data['subheader'] : '';
    let instructions = _data['instructions'] !== undefined ? _data['instructions'] : '';
    let popupMsg = this.getPopupVal(_data);
    if (!!popupMsg['popUpMsg']) {
      let option = {};
      option['instructions'] = popupMsg['popUpMsg'];
      option['section'] = '';
      option['cpic'] = popupMsg['cpic'];
      this.setCustomInstructions(option);
      showInstruction = false;
    }

    // Buttons List Preparation
    buttonInputs = this.prepareButtonList(_data)
    processedData = {
      'name': 'Employment Standard Extension' + i,
      'header': this._ts.instant(headerTxt),
      'sub_header': subHeaderTxt,
      'body': subHeaderTxt,
      'section': section,
      'instructionFlag': showInstruction,
      'instructions': instructions,
      'swipe_left': true,
      'swipe_right': true,
      'arrow_enabled': true,
      'inputs': buttonInputs
    };

    let tempValues = processedData['inputs'];

    // Move the below code to a separate shared method
    for (let j = 0; j < tempInput.length; j++) {
      let tempValidation = [];
      let fieldType = (tempInput[j]['type']).toLowerCase();

      // If name does not come
      if (tempInput[j]['name'] === undefined) {
        if (_data['name'] === 'EMPLOYER_ADDITIONAL_DETAILS') {
          tempInput[j]['name'] = tempInput[j]['label'];
        } else {
        tempInput[j]['name'] = tempInput[j]['id'];
        }
      }

      // Validator setting - Text field
      if (DOTConfig.getFieldTypes().indexOf(fieldType) > -1) {
        this.setCustomCSS(fieldType, tempInput[j]);
        if (!tempInput[j]['validation_data']) {
          tempInput[j]['validation_data'] = tempInput[j]['validation'];
        } else {
          tempInput[j]['validation'] = tempInput[j]['validation_data'];
        }

        tempValidation = this.setValidations(tempInput[j]['validation']);
        tempInput[j]['validation'] = tempValidation;
      }

      if ((tempInput[j]['type']).toLowerCase() === 'checkbox') {
        tempValidation.push(Validators.pattern('true'));
      }

      if ((tempInput[j]['type']).toLowerCase() === 'checkboxex') {
        tempValidation.push(Validators.pattern('true'));
        if (acicSign) {
          tempInput[j]['acicSign'] = _data['signature_img_data'];
        }
      }

      tempValues.push(tempInput[j]);
    }

    processedData['inputs'] = tempValues;
    finalData.push(processedData);
    return finalData;
  }

  getPrevSwipe(value, obj) {
    console.log("getPrevSwipe value and obj :" + value + ":" + obj + ":");
    this.onCustomFormSwipe.emit(obj);
  }

  
  submit(value: { [name: string]: any }, obj) {
    console.log("submit value and obj :" + value + ":" + obj + ":");

    let indexDetail: Object = {};
    indexDetail['row'] = this.row;
    obj['indexDetail'] = indexDetail;

    this.actionvalue = WotcSharedService.GetActionVal();

    // If there are input fields received from Server
    if (this.finalSourceInputs.length > 0) {
      this.dummyFinalInputData = JSON.parse(JSON.stringify(this.finalSourceInputs));
      this.prepareSubmitData(value);
    }

    // Set Current status based on Next and Save & Exit button click
    this.setStateValues();
    this.setState();
    if (this.currentStatus === 'PENDING') {
      let re = this._eventBus.logoutAnnounced({ dummy: 'workflow_exit' });
      if (re === undefined) return;
    } else if (this.currentStatus !== 'BACK_TO_REVIEW') {
      if (this.dummyFinalInputData[this.counter]['candidate_form_fields_list'] !== undefined) {
        obj['forms'] = [this.dummyFinalInputData[this.counter]];
        this.copyFormIdAndFieldId(obj['forms'][0], obj['form']);
      }
      obj['COMPONENT_STATE'] = this.payLoad;
    }

    this.onCustomForm.emit(obj);
  }

  ngAfterViewInit(): void {
    this.processConfig();
  }

  private processConfig(): void {
    this.PARENT_STAGE_STEP_CONFIG = this.configOptions['PARENT_CONFIG'];
    this.SUB_STEP_ID = EmploymentConfig.subStepIndexMapping['custform']['subStep'];
    this.SUB_STEP_INDEX = EmploymentConfig.subStepIndexMapping['custform']['subStepIndex'];

    this.setState();
  }

  private setState(): void {
    this.payLoad = {
      name: this.PARENT_STAGE_STEP_CONFIG['STAGE_ID'],
      step: this.PARENT_STAGE_STEP_CONFIG['STEP_ID'],
      stepIndex: this.PARENT_STAGE_STEP_CONFIG['STEP_INDEX'],
      subStep: this.SUB_STEP_ID,
      subStepIndex: this.SUB_STEP_INDEX,
      iteration: this.row,
      status: this.currentStatus,
      dbRecordPresent: this.PARENT_STAGE_STEP_CONFIG['IS_DB_RECORD_PRESENT']
    };

    this._store.dispatch({ type: this.currentStatus, payload: this.payLoad });
  }


}
