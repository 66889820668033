import { Component, ViewEncapsulation, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { Routes, Router, RouterModule, ActivatedRoute, NavigationCancel, NavigationError, NavigationStart, NavigationEnd, RoutesRecognized, NavigationExtras } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES, IdleExpiry, SimpleExpiry } from '@ng-idle/core';
import { TranslateService } from '../framework/i18n/translate.service';
import { EventBusService } from '../framework/eventbus.service';
import { LocalStorageService } from '../framework/localstorage.service';
import { PlatformLocation } from '@angular/common';
import { CanComponentDeactivate } from './login/can-deactivate-guard.service';
import { HostListener } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AuthService } from './login/auth.service';
import { SharedService } from './shared/services/shared.service';
import { Title } from '@angular/platform-browser';
import { AppConfig } from './app.config';

import { environment } from '../environments/environment';
import { HooksService } from './shared/services/hooks.service';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Event as RouterEvent } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { filter, takeUntil } from 'rxjs/operators';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { IdTokenClaims } from '@azure/msal-common';
import { AccountInfo, EventMessage, EventType, AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { jwtDecode } from 'jwt-decode';

declare var window: any;
declare var jQuery: any;
declare var document: any;

function promptUser(event: VersionReadyEvent): boolean {
  return true;
}

type LinkPolicyType = 'signin' | 'password-reset' | 'restart-signin' | 'restart-password-reset';
type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string,
  tfp?: string,
};

@Component({
  selector: 'pa-app',
  templateUrl: './app.template.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    HooksService,
    AuthService,
    EventBusService,
    SimpleExpiry,
    { provide: IdleExpiry, useExisting: SimpleExpiry },
    Idle
  ]
})

export class AppComponent implements CanComponentDeactivate, AfterViewInit {
  translatedText: string;
  isAuthyError: boolean = false;
  // Alert user about Browser reload & Browser close event in PA
  @HostListener('window:beforeunload')
  canDeactivate(): any {
    return !this._service.checkLoggedIn();
  }
  private readonly _destroying$ = new Subject<void>();
  stageName: any;
  currentUrl: String;
  splitedUrl: any;
  translateTitle: string;
  dynamicTitle: string;
  waitMessage: string;
  authErrorMsg: string;
  okButtonText: string;
  default_chat_exp: string;
  isAuthyLoggedIn = false;
  clientColor?: string;
  clientLogoUrl?: string;

  constructor(
    private _hookService: HooksService,
    private _translate: TranslateService,
    private _eventBus: EventBusService,
    private idle: Idle,
    private _location: PlatformLocation,
    private _router: Router,
    private _route: ActivatedRoute,
    private _service: AuthService,
    private localStorage: LocalStorageService,
    private shareService: SharedService,
    private titleService: Title,
    private swUpdate: SwUpdate,
    @Inject(DOCUMENT) private document: any,
    @Inject(MsalService) private authService: MsalService,
    @Inject(MsalBroadcastService) private msalBroadcastService: MsalBroadcastService,
  ) {
    const env = environment;
    this.default_chat_exp = env.default_chat_exp;
    this.authErrorMsg = this._translate.instant('AUTH_ERROR_MSG');
    this.okButtonText = this._translate.instant('COMMONS_OKAY');

    this._eventBus.sessionInvalidReceivedAnnounced$.subscribe(item => {
      if (!!item && item['isSessionValid']) {
        this.authErrorMsg = this._translate.instant('INVALID_SESSION');
        this.okButtonText = this._translate.instant('COMMONS_OKAY');
      }
    })

    this._eventBus.languageInInviteReceived$.subscribe(item => {
      if (!!item && item['isSessionValid']) {
        this.authErrorMsg = this._translate.instant('INVALID_SESSION');
        this.okButtonText = this._translate.instant('COMMONS_OKAY');
      } else {
        this.authErrorMsg = this._translate.instant('AUTH_ERROR_MSG');
        this.okButtonText = this._translate.instant('COMMONS_OKAY');
      }

      if (!!item && item['translationsLanguage']) {
        if (this._translate.currentLang !== item['translationsLanguage']) {
          this._translate.use(item['translationsLanguage']);
        }
      }      
      if (!!item && item['receivedLanguage']) {
        this.loadSalesForceChat(AppConfig.getSalesForceChatLanguage(item['receivedLanguage']));
      }
    });
    
    _router.events.subscribe(
      (event: RouterEvent): void => {
        var globalModal = document.getElementById("globalModal");

        if (event instanceof NavigationStart) {
          this.waitMessage = this._translate.instant('WAIT_ON_AGGREMENT_MODAL_TEXT');
          globalModal.style.display = "block";
        } else if (event instanceof NavigationEnd || event instanceof NavigationError) {
          globalModal.style.display = "none";
        } else if(event instanceof NavigationCancel) {
          this.logoutApp();
          globalModal.style.display = "none";
        } else if (event instanceof RoutesRecognized) {
          if (event['id'] !== 1 && event['url'] === '/' && event['urlAfterRedirects'] === '/') {
            this.logoutApp();
          }
        }
      }
    );

    _location.onHashChange(() => {
      // console.log(_router.url, 'LOGIN DEBUG');
      if (_router.url === '/')
        window.history.forward();

      if (_router.url.indexOf('login') > 0)
        window.history.forward();

      // Check user_email from localDB to confirm the logged in context
      if (_service.checkLoggedIn()) {
        let locHref = window.location.href.split('#/');

        if (!!locHref && locHref[1] !== "" /*&& locHref[1].indexOf("yoti-") === -1*/) {
          jQuery('#logout_confirmation_modal').modal('show');
          window.history.forward();
        } else {
          // Do not show logoutmodal when refresh/edit url, as page already redirected to login and propagtion could not be stopped.
          // Handled clearing of localstorage in NavigationCancel and RoutesRecognized above
        }
      } else {
        jQuery('#back-modal').modal('hide');
      }
    });

    let _timeout: any = this.localStorage.getItem('session_timeout');

    if (_timeout != null) {
      _timeout = parseInt(_timeout);
    } else {
      // one hour
      _timeout = 3600;
    }

    idle.setIdle(_timeout);
    idle.setTimeout(_timeout);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // Subscribe to idle events. Add your logic on how the application should respond, such as displaying
    // a warning dialog onIdleStart, or redirecting to logout page onTImeout, etc.
    idle.onIdleStart.subscribe(() => {
    });

    idle.onIdleEnd.subscribe(() => {
    });

    idle.onTimeoutWarning.subscribe((countdown: number) => {
    });

    idle.onTimeout.subscribe(() => {
      this.doLogout();
    });

    // start watching for idleness right away.
    idle.watch();
  }

  doLogout() {
    this._eventBus.logoutAnnounced({
      dummy: 'logout'
    });

    this.closeModal();
  }
  
  ngOnInit() {
    let isAuthyCallback = this.localStorage.getItem("isAuthyCallback");
    if (!!isAuthyCallback) {
      this.doAuthyCheck();
    }
    
    if (this.swUpdate.isEnabled && environment.production) {
      this.swUpdate.versionUpdates
        .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
        .subscribe(evt => {
          if (promptUser(evt)) {
            // Reload the page to update to the latest version
            console.log('service worker reload - ', evt.currentVersion + ' , ' + evt.latestVersion);
            document.location.reload();
          }
        });
    }
    
    this.shareService.customFormVariables = {};
    this.pageRefreshAndroid();
    this.setDefaultLang();
    this.setDynamicTitle();

    this.localStorage.setItem('websdk_vendor',environment.websdk_vendor);
    this.localStorage.setItem('verification_api_vendor',environment.verification_api_vendor);
    this.localStorage.setItem("invite_url", window.location.href);
    // console.log('$$$WEB - VENDOR$$$ ' + this.localStorage.getItem('websdk_vendor'));
    // console.log('$$$API - VENDOR$$$ ' + this.localStorage.getItem('verification_api_vendor'));

    if (window.location.href.indexOf('key') < 0) {
      try {
        if (!this.shareService.isSupportedMobileOs() ) {
          this._router.navigate(['/unsupported-device'], {queryParams:{unsupportedDeviceVersion: true}});
          return;
        }

        if (!this.shareService.isSupportedBrowserVersion() ) {
          this._router.navigate(['unsupported-browsing']);
          return;
        }
      } catch(error) {
        console.log('failed find device details => ' + error);
      }
    }
  }
  
  ngAfterViewInit(): void {
    // Check if current url contains parameter key
    let url = window.location.href;
    let urlArr = url.split('?key=');
    let urlParams = urlArr[1];

    // Check if undefined and load the chat
    if (urlParams === undefined) {
      this.loadSalesForceChat('en');
    }
  }

  setDefaultLang() {
    // console.log(' #--> Now the browser default lang is ' + navigator.language.toLowerCase());
    if (environment &&
      environment.hasOwnProperty('languageEditEnabled') &&
      environment.languageEditEnabled === true) {
        // console.log('languageEditEnabled hense setting default lang is : zu' );
        this.shareService.setDefaultLang('zu');
      } else {
        this.shareService.setDefaultLang(navigator.language.toLowerCase());
      }
  }

  pageRefreshAndroid() {
    window.addEventListener('load', function () {
      let maybePreventPullToRefresh = false;
      let lastTouchY = 0;
      let touchstartHandler = function (e) {
        if (e.touches.length != 1) return;
        lastTouchY = e.touches[0].clientY;
        // Pull-to-refresh will only trigger if the scroll begins when the
        // document's Y offset is zero.
        maybePreventPullToRefresh = window.pageYOffset === 0;
      };

      let touchmoveHandler = function (e) {
        let touchY = e.touches[0].clientY;
        let touchYDelta = touchY - lastTouchY;
        lastTouchY = touchY;

        if (maybePreventPullToRefresh) {
          // To suppress pull-to-refresh it is sufficient to preventDefault the
          // first overscrolling touchmove.
          maybePreventPullToRefresh = false;
          if (touchYDelta > 0) {
            e.preventDefault();
            return;
          }
        }
      };

      document.addEventListener('touchstart', touchstartHandler, false);
      document.addEventListener('touchmove', touchmoveHandler, false);
    });
  }

  keyboardEventMobile() {
    jQuery('body').on('focusin', 'input, textarea', function (event) {
      if (navigator.userAgent.indexOf('Android') > -1) {
        let scroll = jQuery(this).offset();
        window.scrollTo(0, scroll);
      }
    });
  }

  closeModal() {
    jQuery('.modal-backdrop').css({
      'position': 'relative'
    });
  }

  closeErrorModal() {
    jQuery('#error-modal').modal('hide');
  }

  closeAuthErrorModal() {
    this.logoutApp();
    this._router.navigate(['./login']);
    jQuery('#auth-error-modal').modal('hide');
  }

  setDynamicTitle() {
    this._router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.stageName = e.url;
        this.dynamicTitleDisplay(this.stageName);
      }
    });
  }

  dynamicTitleDisplay(NavEnd: String) {
    this.splitedUrl = NavEnd.split("/");
    this.stageName = (this.splitedUrl[this.splitedUrl.length - 1]);
    if (this.stageName == ':token') {
      this.stageName = (this.splitedUrl[this.splitedUrl.length - 2])
    }

    if (this.translateTitle !== null && this.translateTitle !== undefined && this.translateTitle !== '') {
      this.dynamicTitle = this._translate.instant(this.translateTitle);
      this.dynamicTitle = this.dynamicTitle.charAt(0).toUpperCase() + this.dynamicTitle.slice(1).toLowerCase();
      for (let i = 0; i < this.dynamicTitle.length; i++) {
        if (this.dynamicTitle.charAt(i) == ' ')
          this.dynamicTitle.charAt(i + 1).toUpperCase()
      }
      this.dynamicTitle = 'First Advantage - ' + this.dynamicTitle;
      this.titleService.setTitle(this.dynamicTitle);
    } else {
      this.titleService.setTitle('First Advantage');
    }
  }

  logoutApp() {
    let isAuthyCallback = this.localStorage.getItem("isAuthyCallback");
    if (isAuthyCallback) {
      this.authService.logout();
    }
    this._eventBus.hideHeaderAnnounced({ userInfoShownValue: false });
    window.track_event("logout and delete session", { stage_name: 'sub menu', action_value: 'continue clicked, logout confirmed' }, true, true);
    this.shareService.raisingEventTermsServiceHide(this._eventBus, true);
    this._service.logout();
 
    if (window.hasOwnProperty('safeNotifyHost')) window.safeNotifyHost('signed_out', null);
  }

  @HostListener('window:beforeunload')
  async ngOnDestroy() {
    const locHref = window.location.href;
    if (!!locHref && locHref.indexOf("yoti-") === -1) {
      this.logoutApp();
    }
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  loadSalesForceChat(languageCode) {
    let that = this;
    
    if (!window.embedded_svc) {
      var s = document.createElement('script');
      s.setAttribute('src', environment.salesForceChatEmbeddedServiceMinJS);
      s.onload = function() {
        that.initESW(languageCode, null);
      };
      var scriptId = 'dynamicScript'; 
      s.id = scriptId;
      document.body.appendChild(s);
    } else {
      this.initESW(languageCode, environment.salesForceChatBaseUrl);
    }
  }

  initESW(langCode, gslbBaseURL) {
    let lang = !!langCode ? langCode : 'en';
    let embedded_svc = window.embedded_svc;
       
    embedded_svc.settings.displayHelpButton = environment.salesForceChatSettings.displayHelpButton;
		embedded_svc.settings.language = lang;
		embedded_svc.settings.defaultMinimizedText = this._translate.instant('CHAT_ONLINE');
    embedded_svc.settings.disabledMinimizedText = this._translate.instant('CHAT_OFFLINE');
    embedded_svc.settings.loadingText = this._translate.instant('CHAT_LOADING');
		embedded_svc.settings.enabledFeatures = environment.salesForceChatSettings.enabledFeatures;
		embedded_svc.settings.entryFeature = environment.salesForceChatSettings.entryFeature;
		embedded_svc.init(
			environment.salesForceChatSettings.initEnvUrl,
			environment.salesForceChatSettings.initAgentUrl,
			gslbBaseURL,
			environment.salesForceChatSettings.initParam1,
			environment.salesForceChatSettings.initAgentName,
			{
				baseLiveAgentContentURL: environment.salesForceChatSettings.customSettings.baseLiveAgentContentURL,
				deploymentId: environment.salesForceChatSettings.customSettings.deploymentId,
				buttonId: environment.salesForceChatSettings.customSettings.buttonId,
				baseLiveAgentURL: environment.salesForceChatSettings.customSettings.baseLiveAgentURL,
				eswLiveAgentDevName: environment.salesForceChatSettings.customSettings.eswLiveAgentDevName,
				isOfflineSupportEnabled: environment.salesForceChatSettings.customSettings.isOfflineSupportEnabled
			}
		);
    
    console.log('emb settings --- ', embedded_svc);
  }

  doAuthyCheck() {
    const hash = window.location.hash;
    const search = new URLSearchParams(decodeURIComponent(hash.substring(1)));
    const stateParts = search.get('state')?.split('|') ?? [];
    const clientInfoEncoded = stateParts?.find((p) => p.startsWith('client_info='))?.split('=')[1];
    const clientInfoDecoded = clientInfoEncoded ? window.atob(clientInfoEncoded) : null;
    const clientInfo = clientInfoDecoded ? JSON.parse(clientInfoDecoded) : null;
    
    console.log('Client Info State', clientInfo);
    if (clientInfo) {
      this.clientColor = clientInfo.color;
      this.clientLogoUrl = clientInfo.logo;
    }
    
    // initializes MSAL and configures redirect route.
    this.authService.handleRedirectObservable().subscribe();

    // interaction none means that no auth is in progress.
    // and the application is ready to start.
    let that = this;
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        that.setIsAuthyLoggedIn();
        const allAccounts = that.authService.instance.getAllAccounts();

        if (allAccounts.length > 0) {
          that.setActiveAccount(allAccounts[0]);
        }

        // if the magic link is used to login, redirect to the appropriate policy
        const query = new URLSearchParams(window.location.search);
        
        if (query.has('type')) {
          const type = query.get('type') as LinkPolicyType;

          // if the user is logged in perform a logout and redirect back to the current page.
          if (that.isAuthyLoggedIn) {
            that.authService.logoutRedirect({
              authority: environment.policies.authorities.signIn.authority,
              postLogoutRedirectUri: window.location.href,
            });
            
            return;
          }
          
          switch (type) {
            // if the type is signin or password-reset, then it's a magic link and should redirect to the appropriate policy.
            case 'signin':
            case 'password-reset': {
              const token = query.get('token');
              const authority = environment.magicLinkPolicies[type];
              
              if (type && token && authority) {
                const decoded = jwtDecode(token);
                const now = Date.now() / 1000;
                
                if (decoded.exp && decoded.exp <= now) {
                  this.localStorage.setItem("authyPolicyType", type);
                  this.localStorage.setItem("isAuthyTokenExpired", true);
                  return;
                }
                // It's up to the application if it wants to do this or not, but the token could be decoded here
                // and the expiration time could be checked to see if it's still valid. If it's expired you could redirect
                // the user directly to the appropriate policy to send a new email.

                // if the user is not logged in, then redirect to the appropriate policy.
                that.authService.loginRedirect({
                  authority: authority,
                  scopes: environment.policies.scopes,
                  prompt: 'login',
                  extraQueryParameters: { id_token_hint: token },
                });
              }

              return;
            }
            case 'restart-signin':
            case 'restart-password-reset': {
              // if the user clicks on a restart link in the email, then redirect to the appropriate policy.
              const emailAddress = query.get('email');
              const authority =
                type === 'restart-signin'
                  ? environment.policies.authorities.signIn.authority
                  : environment.policies.authorities.resetPassword.authority;

              that.authService.loginRedirect({
                authority: authority,
                scopes: environment.policies.scopes,
                prompt: 'login',
                extraQueryParameters: { login_hint: encodeURIComponent(emailAddress ?? '') },
              });

              return;
            }
          }
        }
        
        // if the user is logged in, and on the default route, redirect to the home page
        if (that.isAuthyLoggedIn && that._router.url === '/') {
          that.localStorage.setItem("isAuthyLoggedIn", true);
          
          // this needs to make sure it's on the default route, otherwise the user will be redirected
          // to the home page when they are on a different route and reloads the page.
          that._router.navigate(['/invite/authytoken']);
        } else {
          // In certain conditions you can get a flash of the signed-out page
          // the timeout is 3secs just to make sure the loader shows given the built in
          // delay of the loader component.
          if (!that.isAuthyError) {
            that.showGlobalModal();
            
            setTimeout(() => {
              that.authService.loginRedirect({
                authority: environment.policies.authorities.signIn.authority,
                scopes: [],
              });
            }, 1000);
          }
        }
      });

      this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        const idToken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;
        let accessToken = payload.accessToken;
        that.localStorage.setItem('access_token', accessToken);
        window.track_event("accesstoken stored", { stage_name: 'app', action_value: 'clicked' }, true, true);

        // I am not sure why, sometimes the policy comes in tfp, sometimes in acr.
        // tfp (trust framework policy) is supposed to be the correct one, but acr (authentication context class reference) is also used.
        if (
          idToken.tfp === environment.policies.names.signUp ||
          idToken.tfp === environment.policies.names.signIn ||
          idToken.tfp === environment.policies.names.signInByToken ||
          idToken.tfp === environment.policies.names.resetPassword ||
          idToken.acr === environment.policies.names.signUp ||
          idToken.acr === environment.policies.names.signIn ||
          idToken.acr === environment.policies.names.signInByToken ||
          idToken.acr === environment.policies.names.resetPassword
        ) {
          // this will trigger the account added event and redirect to the appropriate page.
          that.setActiveAccount(payload.account);
        }

        return result;
      });
      
      this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        console.log('MSAL Error', result.eventType);
        // when the magic link is used and the token is invalid
        const { error } = result;
        
        let state: string | undefined = undefined;
        
        if (this.clientColor || this.clientLogoUrl) {
          state = `client_info=${window.btoa(
            JSON.stringify({
              color: this.clientColor,
              logo: this.clientLogoUrl,
            })
          )}`;
        }

        if (error?.message.includes('AADB2C90240')) {
          //alert the user //TODO
          return;
        }
        
        // when the magic link is used and the token is expired
        if (error?.message.includes('AADB2C90208')) {
          that.isAuthyError = true;
          this._router.navigate(['expired-link']);
          
          return;
        }

        // when there was a failure in sending the email
        if (error?.message.includes('AADB2C90261')) {
          //alert the user //TODO
          return;
        }

        // Checking for the forgot password error. Learn more about B2C error codes at
        // https://learn.microsoft.com/azure/active-directory-b2c/error-codes

        // when the password reset is invoked from another policy.
        if (error?.message.includes('AADB2C90118')) {
          that.isAuthyError = true;
          that.showGlobalModal();
          
          that.authService.loginRedirect({
            authority: environment.policies.authorities.resetPassword.authority,
            scopes: environment.policies.scopes,
            state,
            extraQueryParameters: {
              clientColor: encodeURIComponent(this.clientColor ?? ''),
              clientLogoUrl: encodeURIComponent(this.clientLogoUrl ?? ''),
            }
          });
          
          return;
        }

        // it's not possible to add hyperlinks to a policy page, so a custom error is needed
        // this is the custom error for when the start again link is clicked after the email verification magic link is clicked
        // when cancel link is clicked
        if (error?.message.includes('AAD_Custom_Authy0001') || error?.message.includes('AADB2C90091')) {
          that.isAuthyError = true;
          that.showGlobalModal();
          
          that.authService.loginRedirect({
            authority: environment.policies.authorities.signIn.authority,
            scopes: environment.policies.scopes,
            state,
            extraQueryParameters: {
              clientColor: encodeURIComponent(this.clientColor ?? ''),
              clientLogoUrl: encodeURIComponent(this.clientLogoUrl ?? ''),
            }
          });
          
          return;
        }
        
        // this is the custom error for when the start again link is clicked after the reset password magic link is clicked
        if (error?.message.includes('AAD_Custom_Authy0002')) {
          that.isAuthyError = true;
          that.showGlobalModal();
          
          this.authService.loginRedirect({
            authority: environment.policies.authorities.resetPassword.authority,
            scopes: environment.policies.scopes,
            state,
            extraQueryParameters: {
              clientColor: encodeURIComponent(this.clientColor ?? ''),
              clientLogoUrl: encodeURIComponent(this.clientLogoUrl ?? ''),
            }
          });

          return;
        }
        
        if (error?.message.includes('AAD_Custom_Authy0003')) {
          //alert the user //TODO
          return;
        }
        
        if (error?.message.includes('AADB2C90075') && error.message.includes('REST-VerifyToken')) {
          //alert the user //TODO
          return;
        }

        // if there is an error this should redirect to a generic error page, unless it's something that can be recovered from,
        // such as the reset password requested error.
      });
  }

  setIsAuthyLoggedIn(): void {
    this.isAuthyLoggedIn = this.authService.instance.getAllAccounts().length > 0;
  }

  setActiveAccount(accountInfo: AccountInfo): void {
    if (!this.isAuthyLoggedIn) return;

    this.authService.instance.setActiveAccount(accountInfo);

    const claims = this.authService.instance.getActiveAccount()?.idTokenClaims as {
      name?: string;
      extension_invite?: string;
      inviteAuthType? : string;
    };
    
    if (!claims) return;
    
    const inviteAuthType = claims.inviteAuthType;
    const inviteKey = claims.extension_invite;
    this.localStorage.setItem("inviteAuthType", inviteAuthType);
    this.localStorage.setItem("extension_invite", inviteKey);
    
    if (!inviteKey) return;
  }
  
  showGlobalModal() {
    let globalModal = document.getElementById("globalModal");
    globalModal.style.display = "block";
  }
}
