<div class="bg-white">
  <div class="container">
    <div class="row">
      <header class="header-img">
        <div class="arrow"></div>
        <div class="col-xs-6 col-sm-6 col-md-6 padOverride">
          <div class="logo-img">
            <div class="logo-container"></div>
          </div>
        </div>
        <div class="modal fade custom_modal" id="dashboard-modal" tabindex="-1" role="Application" aria-labelledby="headerLeavePageStatus" data-backdrop="static"
          data-keyboard="false">
          <div class="modal-dialog sign-modal">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
              </div>
              <div class="transparentbg modal-body">
                <h4 class="modal-title" id="headerLeavePageStatus"> {{ headerModelText }}
                </h4>
              </div>
              <div class="modal-footer">
                <div class="row">
                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <button type="submit" class="btn button font_extrabold" data-dismiss="modal"
                      data-toggle="dashboard-modal">{{ changedMind }}</button>
                  </div>
                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <button type="button" class="btn button grow font_extrabold" data-dismiss="modal"
                      data-toggle="dashboard-modal" (click)="redirectToDashboard()">{{ understandContent }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xs-6 col-sm-6 col-md-6 padOverride" *ngIf="!isConsentMenu && !isCustomerInstructions && !hideHeaderMenu">
          <div *ngIf="isShowLangIcon" class="dropdown headDropdown selectLanguage padtop15">
            <language-picker [languagePickerConfig]="displayLanguage" (onLanguagePick)="updateLanguage($event)">
            </language-picker>
          </div>
          <div *ngIf="isUserInfoShown" class="profile-set pull-right">
            <div class="profile-pic">
              <!-- {{ 'hello world' | translate }}-->
              <img src="/assets/img/newpp.png" class="" alt="Profile-picture">
            </div>
            <div class="dropdown headDropdown visible-xs">
              <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">
                <span class=""></span><i class="fa fa-ellipsis-v" aria-hidden="true"></i></button>
              <ul class="dropdown-menu">
                <li *ngIf="url !=='/dashboard'" tabindex="0">
                  <a (click)="showPopup('#dashboard-modal')">{{ dashboardText }}</a>
                </li>
                <li tabindex="0">
                  <a (click)="redirectToSetting()">{{ settingsText }}</a>
                </li>
                <li *ngIf="url ==='/dashboard'" (click)="redirectProfile()" tabindex="0">
                  <a>{{ profileHeaderText }}</a>
                </li>
                <li (click)="logOut()" tabindex="0">
                  <a>{{ signoutText }}</a>
                </li>
              </ul>
            </div>
            <label class="welcome">{{ welcomeText }}, <span
                class="change-color">{{loggedInUser}}</span></label>
            <div class="dropdown headDropdown hidden-xs">
              <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">
                <span class=""></span><i class="fa fa-ellipsis-v" aria-hidden="true"></i></button>
              <ul class="dropdown-menu">
                <li *ngIf="url !=='/dashboard'" tabindex="0">
                  <a (click)="showPopup('#dashboard-modal')">{{ dashboardText }}</a>
                </li>
                <li tabindex="0">
                  <a (click)="redirectToSetting()">{{ settingsText }}</a>
                </li>
                <li *ngIf="url ==='/dashboard'" (click)="redirectProfile()" tabindex="0">
                  <a>{{ profileHeaderText }}</a>
                </li>
                <li (click)="logOut()" tabindex="0">
                  <a>{{ signoutText }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </header>
    </div>
  </div>
</div>

<!--
  Pop-Up Modals will be parsed when Pop-Up is required to be shown.
  This is required for Multilingual - for pop-up text to reflect
  the language of choice.
 -->
<div *ngIf="isUserInfoShown" class="modal fade custom_modal" id="logout_confirmation_modal" data-backdrop="static"
tabindex="-1" role="Application" aria-labelledby="headerLogoutStatus" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="closeLogoutConfrmnModal()" data-dismiss="modal">&times;</button>
      </div>
      <div class="transparentbg modal-body">
        <h4 class="modal-title" id="headerLogoutStatus">{{logoutHeaderModel1Text}}</h4>
        <h4>{{logoutHeaderModel2Text}}</h4>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-xs-12 col-sm-4 col-md-4  p_l_0  p_r_0 m-r-15">
            <button type="button" class="btn button font_extrabold" data-dismiss="modal"
              (click)="logoutApp()">{{continueBtnText}}</button>
          </div>
          <div class="col-xs-12 col-sm-4 col-md-4 p_l_0  p_r_0 m-r-15">
            <button type="button" class="btn button_wbg grow font_extrabold" data-dismiss="modal"
              (click)="closeLogoutConfrmnModal()">{{backBtnText}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Please wait modal -->
<div class="modal fade custom_modal" id="plsWaitAMoment" tabindex="-1" role="Application" aria-labelledby="headerWaitStatus" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
      </div>
      <div class="transparentbg modal-body">
        <h4 class="modal-title m-t-10" *ngIf="updateLanguageText" id="headerWaitStatus">{{waitModelText}}</h4>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>
