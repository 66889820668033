import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { EmploymentConfig } from '../employment.config';
import { Store } from '@ngrx/store';
import * as ACTIONS_INTERFACE from '../../actions';
import { SharedService } from './../../shared/services/shared.service';
import { BehaviorSubject } from 'rxjs';

import { EventBusService } from 'framework/eventbus.service';
import { debounceTime, distinctUntilChanged, switchMap, catchError, filter } from 'rxjs/operators';
import { CustomSelectComponent } from 'framework/customSelect';
import { of } from 'rxjs';
import { AppConstants } from 'app/app.constants';
import { LocalStorageService } from 'framework/localstorage.service';
import { EmploymentService } from '../employment.service';

declare var google: any;
declare var jQuery: any;

@Component({
  selector: 'reporting-manager-details',
  templateUrl: './reporting-manager-details.component.html',
  styleUrls: ['./reporting-manager-details.component.css']
})
export class ReportingManagerDetailsComponent implements OnInit {

  @Input('group')
  public empSubForm: FormGroup;

  @Input() row: any;
  @Input() userDetails: any;
  @Input() empType: string;
  @Input() configOptions: any;
  @Input() clipboardOptions: any;
  @Input() isReviewAdd: boolean;
  @Input() isReviewEdit: boolean;
  @Input() options: any;
  @Input() isCheckSelfEmploymentEnabled: boolean;
  @Input() isPaDOTDirectEnabled: boolean;
  @Input() checkEmployerPhoneEmailOptional: any;

  managerCountryCodeTypeahead = new EventEmitter<string>();
  managerCountryResidenceTypeahead = new EventEmitter<string>();
  managerResidenceCountryShortName: any;
  managerResidenceStateTypeahead = new EventEmitter<string>();
  managerResidenceStateCode: any;
  showStateErr: boolean = false;
  
  @Input()
  set inputErrorCode(value) {
    this.docData.next(value);
  };
  @Input() inputIntlSUPCountrySpecificDataRequired: boolean;
  @Output() onGoToProfileBuilder: EventEmitter<any> = new EventEmitter();
  @Output() onManagerDetails: EventEmitter<any> = new EventEmitter();
  @Output() onManagerDetailsSwipe: EventEmitter<any> = new EventEmitter();

  isViewInit;

  static STAGE_ID: string;
  static STEP_ID: string;
  static STEP_INDEX: number;
  public SUB_STEP_ID: string;
  public SUB_STEP_INDEX: number;
  PARENT_STAGE_STEP_CONFIG: Object;

  static BUTTON_CLICKED: string = 'CLICKED_SAVE_EXIT_BUTTON';
  isPhoneNoValid;
  isPhoneNoError: boolean = false;
  errMsg: string;
  currentStatus: string = 'VISITED';
  payLoad: Object;
  startTime: any;
  isReview: boolean = false;
  isValidEmail: boolean = false;
  isValidPhone: boolean = false;
  countrycodelist : [] = [];
  isNumberList: boolean = true;
  codeErrorMsg: boolean = false;
  enableNextButton: boolean = false;
  isValidEmailShow: boolean = true;
  fieldValidate: boolean = false;
  isCurrentHeaderText: boolean = true;
  telCountryCode: any;
  docData = new BehaviorSubject<any>([]);
  selectedManagerCountryCode: any;
  isValueFromATS: boolean = false;
  isEmployerPhoneOptional: boolean = false;
  isEmployerEmailOptional: boolean = false;
  managerResidenceCountryList: [] = [];
  isManagerResidenceCountryError: boolean = false;
  selectedManagerResidenceCountry: any;
  errorMgsShow: boolean = false;
  managerResidenceStateList: [] = [];
  selectedManagerResidenceState: any;
  isINTLSUPComponentAvailable: boolean = false;

  constructor(private _sharedService: SharedService,
    private _eventBus: EventBusService, // Auto Generated PPA-8745 - Arun Supreet for Save n Exit Patch
    private _store: Store<ACTIONS_INTERFACE.AppState>) {
    this.startTime = new Date().getTime();
  }

  ngOnInit(): void {
    this.telCountryCode = '';
    this.isPhoneNoValid = true;
    this.isViewInit = false;
    this.isINTLSUPComponentAvailable = this._sharedService.checkDistinctComponentAvailable('INTLSUP');
    
    if (!!this.isReviewAdd || !!this.isReviewEdit) {
      this.isReview = true;
      this.selectedManagerCountryCode = (<FormControl>this.empSubForm.controls['managerCountryCode']).value;
    }
    
    this.docData
      .subscribe(res => {
        if (res !== undefined) {
          let errorCodeMsg = res.split("_");
          this.errorHandle(errorCodeMsg[0]);
        }
      });

    /**
     * Employment component - setATSData() will send notification
     */
    this._sharedService.reportingManagerNotified$.subscribe(data => {
      this.setEmpTypeControl();
      this.validateEmail(this.empSubForm.value);
      this.validatePhone(this.empSubForm.value);
      this.validateCountryCode(data['manager_country_code']);
      this.selectedManagerCountryCode = data['manager_country_code'];
    });

    
    this.selectedManagerCountryCode = this.empSubForm.controls['managerCountryCode'].value;
    
    if (this.isINTLSUPComponentAvailable && this.inputIntlSUPCountrySpecificDataRequired) {
      this.selectedManagerResidenceCountry = (<FormControl>this.empSubForm.controls['managerResidenceCountry']).value;
      this.selectedManagerResidenceState = (<FormControl>this.empSubForm.controls['managerResidenceStateName']).value;
    }
    
    this.countryCodeServerSideSearch();
    this.managerResidenceCountryServerSideSearch();
    this.managerResidenceStateServerSideSearch();
    
    if (!this.isINTLSUPComponentAvailable) {
      if (!!this.checkEmployerPhoneEmailOptional['employer_phone_optional'] && this.checkEmployerPhoneEmailOptional['employer_phone_optional']) {
        this.isEmployerPhoneOptional = true;
      }
      
      if (!!this.checkEmployerPhoneEmailOptional['employer_email_optional'] && this.checkEmployerPhoneEmailOptional['employer_email_optional']) {
        this.isEmployerEmailOptional = true;
      }
    }
  }

  errorHandle(error) {
    if (error === 'EMPINVALIDISDCODE') {
      this.codeErrorMsg = true;
    } else if (error === 'EMPINVALIDPHONENUMBER') {
      this.isPhoneNoValid = false;
      this.isPhoneNoError = false;
      this.errMsg = "REFERENCE_NUMBER_ERROR_1";
    } else if (error === 'EMPINVALIDISDCODE EMPINVALIDPHONENUMBER') {
      this.codeErrorMsg = true;
      this.isPhoneNoValid = false;
      this.isPhoneNoError = false;
      this.errMsg = "REFERENCE_NUMBER_ERROR_1";
    }
  }

  ngAfterViewInit(): void {
    this.processConfig();
    this.isPhoneNoValid = true;
    this.isViewInit = true;
    this.setEmpTypeControl();
    
    setTimeout(() => {
      let managerCountryCodeValue = (<FormControl>this.empSubForm.controls['managerCountryCode']).value;
      this.validateEmail(this.empSubForm.value);
      this.validatePhone(this.empSubForm.value);
      this.validateCountryCode(managerCountryCodeValue);
    }, 200);
    
    this.countryLookUp();
  }

  private processConfig(): void {
    this.PARENT_STAGE_STEP_CONFIG = this.configOptions['PARENT_CONFIG'];
    this.SUB_STEP_ID = EmploymentConfig.subStepIndexMapping['reporting_manager_details']['subStep'];
    this.SUB_STEP_INDEX = EmploymentConfig.subStepIndexMapping['reporting_manager_details']['subStepIndex'];

    this.setState();
  }

  private setState(): void {
    this.payLoad = {
      name: this.PARENT_STAGE_STEP_CONFIG['STAGE_ID'],
      step: this.PARENT_STAGE_STEP_CONFIG['STEP_ID'],
      stepIndex: this.PARENT_STAGE_STEP_CONFIG['STEP_INDEX'],
      subStep: this.SUB_STEP_ID,
      subStepIndex: this.SUB_STEP_INDEX,
      iteration: this.row,
      status: this.currentStatus,
      dbRecordPresent: this.PARENT_STAGE_STEP_CONFIG['IS_DB_RECORD_PRESENT']
    };
    
    this._store.dispatch({ type: this.currentStatus, payload: this.payLoad });
  }

  ngOnChanges() {
    if (this.isViewInit && this.empType === 'prior') {
      this.setEmpTypeControl();
    }

    if (this.empType === 'current') {
      this.isCurrentHeaderText = true;
    } else {
      this.isCurrentHeaderText = false;
    }
  }

  validateField() {
    if (this.isINTLSUPComponentAvailable && this.inputIntlSUPCountrySpecificDataRequired) {
      if (!this.empSubForm.get('managerResidenceCountryCode').valid
        || !this.empSubForm.get('managerResidenceStateCode').valid
        || !this.empSubForm.get('managerCountryCode').valid
        || !this.empSubForm.get('phoneNumber').valid
        || !this.empSubForm.get('managerEmail').valid
        || !this.empSubForm.get('position').valid
        || !this.empSubForm.get('managerName').valid) {
        this.fieldValidate = false;
      }
      else {
        this.fieldValidate = true;
      }
    } else {
      if ((!this.isEmployerPhoneOptional && !this.empSubForm.get('managerCountryCode').valid)
        || (!this.isEmployerPhoneOptional && !this.empSubForm.get('phoneNumber').valid)
        || (!this.isEmployerEmailOptional && !this.empSubForm.get('managerEmail').valid)
        || !this.empSubForm.get('position').valid
        || !this.empSubForm.get('managerName').valid) {
        this.fieldValidate = false;
      }
      else {
        this.fieldValidate = true;
      }
    }
  }

  private setEmpTypeControl() {
    (<FormControl>this.empSubForm.controls['type']).setValue(this.empType);
  }

  doSwipe(direction: string, data) {
    if (direction === 'swiperight') {
      this.getPrevSwipe(data);
    }
    
    if (direction === 'swipeleft') {
      if (!!data['managerName']) {
        this.onManagerDetailsNext(data);
      }
    }
  }

  keyupEmpnumber() {
    this.isNumberList = true;
    this.validateField();
  }

  closeCodeError() {
    this.codeErrorMsg = false;
  }

  closePhoneNoError() {
    this.isPhoneNoError = true;
  }

  openPhoneNoError() {
    this.isPhoneNoError = false;
    this.isPhoneNoValid = false;
  }

  getPrevSwipe(data) {
    this.codeErrorMsg = false;
    this.isPhoneNoValid = true;
    this.isPhoneNoError = true;

    let indexDetail: Object = {};
    indexDetail['row'] = this.row;
    data['indexDetail'] = indexDetail;
    this.onManagerDetailsSwipe.emit(data);
  }

  saveExit(obj) {
    // PPA-8745 - Auto-generated via regex - Arun, Supreet, Save n Exit Patch
    var re = this._eventBus.logoutAnnounced({ dummy: "workflow_exit" });
    if (re == undefined) return;
  }
  
  onManagerDetailsNext(obj): void {
    this.codeErrorMsg = false;
    this.isPhoneNoValid = true;
    this.isPhoneNoError = true;

    if (this.currentStatus === 'VISITED') {
      this.currentStatus = 'COMPLETED'
    }

    //Update the state
    this.setState();

    let indexDetail: Object = {};
    indexDetail['row'] = this.row;
    obj['indexDetail'] = indexDetail;
    obj['COMPONENT_STATE'] = this.payLoad;
    obj['startTime'] = this.startTime;
    obj['tel_country'] = this.telCountryCode;
    this.onManagerDetails.emit(obj);
  }

  private goToProfileBuilder() {
    this.onGoToProfileBuilder.emit();
  }

  validateEmail(value) {
    let inputEmail = value['managerEmail'];
    let regexVal = AppConstants.EMAIL_REGEX;
    let emailRegex = new RegExp(regexVal);

    if (inputEmail === "") {
      this.isValidEmail = false;
    } else if (emailRegex.test(inputEmail)) {
      this.isValidEmail = true;
    } else {
      this.isValidEmail = false;
    }
    
    if (this.isEmployerEmailOptional) {
      this.isValidEmail = true;
    }
    
    this.validateField();
  }

  validateEmailFormat(value) {
    let inputEmail = value['managerEmail'];
    let regexVal = AppConstants.EMAIL_REGEX;
    let emailRegex = new RegExp(regexVal);

    if (inputEmail == "") {
      this.isValidEmailShow = false;
    } else if (emailRegex.test(inputEmail)) {
      this.isValidEmailShow = true;
    } else {
      this.isValidEmailShow = false;
    }
    
    if (this.isEmployerEmailOptional) {
      this.isValidEmailShow = true;
    }
  }

  validatePhone(value) {
    let input = value['phoneNumber'];
    let phoneRegex = new RegExp('^(?=.*[0-9])[- ()0-9]+$');
    
    if (input == '' || input === undefined) {
      this.isValidPhone = false;
    } else if (input !== undefined && !phoneRegex.test(input)) { //validate for phone number format on blur
      this.isValidPhone = false;
    } else {
      this.isValidPhone = true;
    }

    if (!this.isINTLSUPComponentAvailable && this.isEmployerPhoneOptional) {
      this.isValidPhone = true;
    }
  }

  private validateCountryCode(value) {
    let code = value;
    
    if (code !== '' || code !== undefined) {
      this.enableNextButton = true;
    } else {
      this.enableNextButton = false;
    }
    
    if (!this.isINTLSUPComponentAvailable && this.isEmployerPhoneOptional) {
      this.enableNextButton = true;
    }
  }

  closeEmailError() {
    this.isValidEmailShow = true;
  }

  openEmailError() {
    this.isValidEmailShow = false;
  }

  private countryCodeServerSideSearch() {
    this.managerCountryCodeTypeahead.pipe(
      distinctUntilChanged(),
      filter(term => {  return (term !== undefined && term !== null) }),
      switchMap(term => this._sharedService.isdCodesLookupSwitch(term)
      .pipe(
        catchError(() => of({ 'isd-code-list': [] }))
        )
      )
    ).subscribe(x => {
      this.countrycodelist = x['isd-code-list'];
      
      if (this.countrycodelist.length > 0) {
        this.isNumberList = true;
        this.codeErrorMsg = false;
        this.enableNextButton = true;
      } else {
        this.isNumberList = false;
        this.codeErrorMsg = true;
        this.enableNextButton = false;
      }
    }, (err) => {
      console.log(err);
      this.countrycodelist = [];
    });
  }

  trackChange(value) {
    let selectedCode = !!value ? value['code'] : '';
    (<FormControl>this.empSubForm.controls['managerCountryCode']).setValue(selectedCode);
    
    this.validateField();
    
    if (selectedCode === '') {
      this.codeErrorMsg = true;
    } else{
      this.codeErrorMsg = false;
    }
    
    if (!this.isINTLSUPComponentAvailable && this.isEmployerPhoneOptional) {
      this.codeErrorMsg = false;
    }
  }
  
  trackManagerResidenceCountryChange(value) {
    let selectedCountryCode = !!value ? value['country_code'] : '';
    let selectedCountryName = !!value ? value['country_name'] : '';
    
    if (!!selectedCountryName) {
      (<FormControl>this.empSubForm.controls['managerResidenceCountry']).setValue(selectedCountryName);
      (<FormControl>this.empSubForm.controls['managerResidenceCountryCode']).setValue(selectedCountryCode);
      
      this.managerResidenceCountryShortName = selectedCountryCode;
    }
    
    this.validateField();
  }

  onClearData() {
    this.countrycodelist = [];
  }
  
  managerResidenceCountryServerSideSearch() {
    this.managerCountryResidenceTypeahead.pipe(
      distinctUntilChanged(),
      filter(term => { return (term !== undefined && term !== null) }),
      switchMap(term => this._sharedService.countryCodeLookupSwitch({countryParam: term})
      .pipe(
        catchError(() => of({ 'country_list': [] }))
        )
      )
    ).subscribe(countryData => {
      this.managerResidenceCountryList = countryData['country_list'];
        
      if (this.managerResidenceCountryList.length > 0) {
        this.isNumberList = true;
        this.isManagerResidenceCountryError = false;
        // this.errorMgsShow = false;
        this.enableNextButton = true;
      } else {
        this.isNumberList = false;
        this.isManagerResidenceCountryError = true;
        // this.errorMgsShow = true;
        this.enableNextButton = false;
      }
    }, (err) => {
      console.log(err);
      this.managerResidenceCountryList = [];
    });
  }
  
  countryLookUp() {
    let countryNameValue = !!(<FormControl>this.empSubForm.controls['managerResidenceCountry']) ? (<FormControl>this.empSubForm.controls['managerResidenceCountry']).value : '';
    
    if(countryNameValue === null || countryNameValue === ''){
      let cnty = !!(<FormControl>this.empSubForm.controls['managerResidenceCountry']) ? (<FormControl>this.empSubForm.controls['managerResidenceCountry']).value: '';
      
      if(!cnty){
        this._sharedService.countryCodeLookupSwitch({countryParam: cnty}).subscribe(countryData => {
        this.managerResidenceCountryList = countryData['country_list'];
        
        if (this.managerResidenceCountryList.length > 0) {
            this.isManagerResidenceCountryError = false;
            // this.errorMgsShow = false;
        } else {
            this.isManagerResidenceCountryError = true;
            // this.errorMgsShow = true;
        }
      }, (err) => {
          console.log(err);
          this.managerResidenceCountryList = [];
      });
      }
    }
  }
  
  openManagerResidenceCountryError() {
    this.isManagerResidenceCountryError = true;
    // this.errorMgsShow = true;
  }
  
  closeManagerResidenceCountryError() {
    this.isManagerResidenceCountryError = !this.isManagerResidenceCountryError;
    // this.errorMgsShow = !this.errorMgsShow;
  }
  
  onClearManagerResidenceData() {
    this.managerResidenceCountryList = [];
    this.managerResidenceStateList = [];
    this.managerResidenceCountryShortName = '';
    (<FormControl>this.empSubForm.controls['managerResidenceCountry']).setValue('');
    (<FormControl>this.empSubForm.controls['managerResidenceCountryCode']).setValue('');
    (<FormControl>this.empSubForm.controls['managerResidenceStateName']).setValue('');
    (<FormControl>this.empSubForm.controls['managerResidenceStateCode']).setValue('');
    
    this.countryLookUp();
  }
  
  trackManagerResidenceStateChange(value) {
    let selectedStateCode = !!value ? value['state_code'] : '';
    
    if (selectedStateCode) {
      (<FormControl>this.empSubForm.controls['managerResidenceStateName']).setValue(value['state_name']);
      (<FormControl>this.empSubForm.controls['managerResidenceStateCode']).setValue(value['state_code']);
      
      this.managerResidenceStateCode = value['state_code'];
    }
   
    this.validateField();
  }
  
  onClearManagerResidenceStateData() {
    console.log("onClearStateData----");
    (<FormControl>this.empSubForm.controls['managerResidenceStateName']).setValue('');
    (<FormControl>this.empSubForm.controls['managerResidenceStateCode']).setValue('');
    
    this.validateField();
    
    this.stateLookUp('', null);
  }
  
  private managerResidenceStateServerSideSearch() {
    this.managerResidenceStateTypeahead.pipe(
      distinctUntilChanged(),
      filter(term => {  return (term !== undefined && term !== null) }),
      switchMap(term => this._sharedService.stateLookupSwitch({ 'countryCode': this.managerResidenceCountryShortName, 'stateParam': term })
      .pipe(
        catchError(() => of({ 'state-data-list': [] }))
        )
      )
    ).subscribe(stateData => {
      this.managerResidenceStateList = stateData['state-data-list'];
    }, (err) => {
      console.log(err);
      this.managerResidenceStateList = [];
    });
  }
  
  stateLookUp(data, event) {
    let stateNameValue = (<FormControl>this.empSubForm.controls['managerResidenceStateName']).value;
    
    if (!stateNameValue || stateNameValue === '') {
      let queryParam = {
        'countryCode': this.managerResidenceCountryShortName,
        'stateParam': data.trim()
      };
      // call shared service to get state datas
      this._sharedService.stateLookupSwitch(queryParam).subscribe(response => {
        let stateData = response;
        console.log('state Data: ' + JSON.stringify(stateData));
        this.managerResidenceStateList = stateData['state-data-list'];
      }, error => {

      });
    }
  }
  
  openShowStateErr() {
    this.showStateErr = false;
  }
  
  closeStateError() {
    this.showStateErr = true;
  }
}
