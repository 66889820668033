import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LocalStorageService } from './../framework/localstorage.service';
import { AppConfig } from 'app/app.config';
import { throwError } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class WorkflowService {
    static _menuMap: Object;
    static _menus: any;
    static _steps: any;
    static _currentStep: any;
    static _currentSubStep: any;
    static _currentSubStepIndex: any;
    static _currentStage: any;
    static _currentStatus: any;
    static _currentStageStatus: any;
    static _crfUdf: any;
    static _allStagesState: any;
    static _allCompletedStagesState: any;
    static _currentIteration: any;
    static _JWTToken: string;
    static dynamicDataConfig: Object;
    static stdConsentDataConfig: Object;
    static workflowState: any;
    static tempResponse: any;
    static isWotcEnable: boolean = false;
    static docBaseURL: string;
    static options: any;
    static WorkFlowFlavour: any;
    static authType: any;
    static signType: any;
    static _lockLevel: any;
    private _setLandingURL: string;
    private _stages: any;
    static _groups: any;
    static _groupIdNameMap: Object;
    static _stageNameGroupIdMap: Object;
    static _groupNameFormIdMap: Object;
    static _currentGroup: any;
    static _currentGroupStatus: any;
    static _bandWidthCheckUrl: any;
    static _groupIdsToHide: any;
    static _disableQnBtnHighlight: boolean = false;
    static AFPCONSENT: string = 'AFPCONSENT';
    static NVNPCONSENT: string = 'NEVADANON-FPSTATEWIDE-NVNP-FORM';
    static WAMVRCONSENT: string = 'WAMVRFORMCONTRACTORVERSION';
    static AUSTRALIANEWZEALANDPRIVACYPOLICYFORM: string = 'AUSTRALIANEWZEALANDPRIVACYPOLICYFORM';
    static AADHAARCONSENT: string = 'AADHAARCONSENT';

    static X_PA_ACTION: string = 'x-pa-action';
    static X_PA_USERID: string = 'x-pa-user-id';


    constructor(private _authHttp: HttpClient, private localStorage: LocalStorageService) {
        // console.log("workflow.service constructor ------");

    }

    // Make HTTP Call with Server API to get the work flow object
    getWorkFlow(id: string): Observable<Object> {
        this.getHeaderOptions();

        return this._authHttp.get(AppConfig.API_ENDPOINT() + '/api/v1/workflow/' + id + '/?t=' + new Date(), WorkflowService.options)
            .pipe(
                map(res => this._extractData(res)),
                map(data => this._doAction(data)),
                catchError(err => this._handleError('get_workflow_failed', err))
            );
    }

    getWorkFlowInfo(id: string): Observable<Object> {
        return this._authHttp.get(AppConfig.API_ENDPOINT() + '/api/v1/workflow/' + id + '/?t=' + new Date(), WorkflowService.options)
            .pipe(
                map(res => this._extractData),
                catchError(err => this._handleError('get_workflowinfo_failed', err))
            );
    }

    private _extractData(res: Object) {
        WorkflowService.tempResponse = res;

        return (res) || {};
    }

    private _doAction(response: Object) {
        WorkflowService._menuMap = AppConfig.getMenuMap();
        WorkflowService._menus = [];
        WorkflowService._steps = [];
        WorkflowService._allStagesState = [];
        WorkflowService._allCompletedStagesState = [];
        WorkflowService._groups = [];
        WorkflowService._groupIdNameMap = {};
        WorkflowService._stageNameGroupIdMap = {};
        WorkflowService._groupNameFormIdMap = {};
        WorkflowService._groupIdsToHide = [];

        /*
        * Get Current Stage & Step info to take user there if present
        */
        WorkflowService._currentStage = response['state']['current_stage'];
        WorkflowService._currentStep = response['state']['current_step'];
        WorkflowService._currentStatus = response['state']['step_status'];
        WorkflowService._currentStageStatus = response['state']['stage_status'];
        WorkflowService._currentSubStep = response['state']['current_sub_step'];
        WorkflowService._currentSubStepIndex = response['state']['current_sub_step_index'];
        WorkflowService._currentIteration = response['state']['current_iteration'];
        WorkflowService._currentGroup = response['state']['current_group'];
        WorkflowService._currentGroupStatus = response['state']['group_status'];

        // Load dynamic data under consent step
        // WorkflowService.dynamicDataConfig = response["workflow"]["stages"][5]['steps'][1]['forms'];

        /*
        * Processing the Stages
        */
        if (!!response && !!response['WORKFLOW_FLAVOR']) {
            WorkflowService.WorkFlowFlavour = response['WORKFLOW_FLAVOR']; // WORK FLOW FLAVOR - STANDARD (CONSENT FIRST STAGE) ,WORK FLOW FLAVOR - CLASSIC (CONSENT LAST STAGE)
        } else {
            WorkflowService.WorkFlowFlavour = 'STANDARD';
        }
        if (!!response && !!response['auth_type']) {
            WorkflowService.authType = response['auth_type']; // auth_type = WET_SIGNATURE if user disagrees in EAuth step
        } else {
            WorkflowService.authType = '';
        }
        if (!!response && !!response['sign_type']) {
            WorkflowService.signType = response['sign_type'];
        } else {
            WorkflowService.signType = '';
        }
        if (!!response && !!response['DISABLE_QUESTION_BUTTON_HIGHLIGHT']) {
            WorkflowService._disableQnBtnHighlight = response['DISABLE_QUESTION_BUTTON_HIGHLIGHT'];
        } else {
            WorkflowService._disableQnBtnHighlight = false;
        }
        this._stages = response['workflow']['stages'];
        let groupStageNameIndexes = {};
        let groupCustomFormStages = ['custom-form-data-v1', 'group-custom-form-data-v1'];

        for (let i = 0; i < this._stages.length; i++) {
            // set a flag when WOTC is present
            if (this._stages[i]['name'] === 'wotc-data-v1') {
                WorkflowService.isWotcEnable = true;
            }
            let stageName = this._stages[i]['name'];
            WorkflowService._menus[i] = this._stages[i]['name'];
            WorkflowService._steps[this._stages[i]['name']] = this._stages[i]['steps'];
            WorkflowService._allStagesState.push(this._stages[i]);

            if (WorkflowService._menuMap[stageName] == undefined) {
                if (this._stages[i]['steps'][0]['forms'] !== undefined &&
                    this._stages[i]['steps'][0]['forms'].length > 0 &&
                    this._stages[i]['steps'][0]['forms'][0]['name'] !== undefined) {
                    let displayName = this._stages[i]['steps'][0]['forms'][0]['name'];
                    // console.log("displayName is :" + displayName + ":" + this._stages[i]['steps'][0]['forms'][0]['name'] + ":");
                    let linkUrl = '/home/customform/';
                    if (groupCustomFormStages.indexOf(stageName) !== -1) {
                        let formsSize = this._stages[i]['steps'][0]['forms'].length;
                        WorkflowService._stageNameGroupIdMap[stageName] = [];
                        groupStageNameIndexes[stageName] = i;
                        if (stageName === 'group-custom-form-data-v1') {
                            linkUrl = '/home/group-custom-form/';
                        } else {
                            linkUrl = '/home/customform/';
                        }
                        for (let j = 0; j < formsSize; j++) {
                            let form = this._stages[i]['steps'][0]['forms'][j];
                            let groupName = form['group_name'];
                            let groupDisplayId = this.getGroupDisplayId(groupName);

                            if (WorkflowService._groups.indexOf(groupDisplayId) === -1) {
                                WorkflowService._groups.push(groupDisplayId);
                                WorkflowService._groupIdNameMap[groupDisplayId] = groupName;
                                WorkflowService._stageNameGroupIdMap[stageName].push(groupDisplayId);
                                if (linkUrl === '/home/customform/' || linkUrl === '/home/group-custom-form/') {
                                    WorkflowService._groupNameFormIdMap[groupDisplayId] = form['id'];
                                }

                                WorkflowService._menuMap[groupDisplayId] = {
                                    'DISPLAY_NAME': groupName,
                                    'LINK': linkUrl + groupDisplayId,
                                    'MENU_IMG': '../../assets/img/consent.png',
                                    'ICON_CLASS': 'icon-search',
                                    'STEPS': [this._stages[i]['steps'][0]['name']],
                                    'ORDER': 0,
                                    'VISIBLE': false
                                };
                                AppConfig.menus[groupDisplayId] = {
                                    'DISPLAY_NAME': groupName,
                                    'LINK': linkUrl + groupDisplayId,
                                    'MENU_IMG': '../../assets/img/consent.png',
                                    'ICON_CLASS': 'icon-search',
                                    'STEPS': [this._stages[i]['steps'][0]['name']],
                                    'ORDER': 0,
                                    'VISIBLE': false
                                };
                            }
                            if (groupDisplayId === WorkflowService.AFPCONSENT) {
                                let afpConsentConfig = this._stages[i]['steps'][0];
                                if (!!afpConsentConfig['preferences'] && afpConsentConfig['preferences']['isAFPEnabled'] !== undefined && !afpConsentConfig['preferences']['isAFPEnabled']) {
                                    WorkflowService._groupIdsToHide.push(groupDisplayId);
                                }
                            }
                            if (groupDisplayId === WorkflowService.NVNPCONSENT) {
                                let afpConsentConfig = this._stages[i]['steps'][0];
                                if (!!afpConsentConfig['preferences'] && afpConsentConfig['preferences']['isNVNPEnabled'] !== undefined && !afpConsentConfig['preferences']['isNVNPEnabled']) {
                                    WorkflowService._groupIdsToHide.push(groupDisplayId);
                                }
                            }
                            if (groupDisplayId === WorkflowService.WAMVRCONSENT) {
                                let wamvrConsentConfig = this._stages[i]['steps'][0];
                                if (!!wamvrConsentConfig['preferences'] && wamvrConsentConfig['preferences']['isWAMVREnabled'] !== undefined && !wamvrConsentConfig['preferences']['isWAMVREnabled']) {
                                    WorkflowService._groupIdsToHide.push(groupDisplayId);
                                }
                            }
                            if (groupDisplayId === WorkflowService.AUSTRALIANEWZEALANDPRIVACYPOLICYFORM) {
                                let aunzPrivacyPolicyConfig = this._stages[i]['steps'][0];
                                if (!!aunzPrivacyPolicyConfig['preferences'] && aunzPrivacyPolicyConfig['preferences']['isAUNZPolicyEnabled'] !== undefined && !aunzPrivacyPolicyConfig['preferences']['isAUNZPolicyEnabled']) {
                                    WorkflowService._groupIdsToHide.push(groupDisplayId);
                                }
                            }                            
                        }
                    } else {
                        WorkflowService._menuMap[stageName] = {
                            'DISPLAY_NAME': displayName,
                            'LINK': linkUrl + stageName,
                            'MENU_IMG': '../../assets/img/consent.png',
                            'ICON_CLASS': 'icon-' + stageName,
                            'STEPS': [this._stages[i]['steps'][0]['name']],
                            'ORDER': 0,
                            'VISIBLE': false
                        };

                        AppConfig.menus[stageName] = {
                            'DISPLAY_NAME': displayName,
                            'LINK': linkUrl + stageName,
                            'MENU_IMG': '../../assets/img/consent.png',
                            'ICON_CLASS': 'icon-' + stageName,
                            'STEPS': [this._stages[i]['steps'][0]['name']],
                            'ORDER': 0,
                            'VISIBLE': false
                        };
                    }
                }
            }
        }
        // console.log("WorkflowService._groupIdsToHide ", WorkflowService._groupIdsToHide);

        let groupCustomFormSize = 0;
        for (let _stageName in groupStageNameIndexes) {
            let groupCustomFormIndex = groupStageNameIndexes[_stageName] + groupCustomFormSize;
            groupStageNameIndexes[_stageName] = groupCustomFormIndex;
            let _groups = WorkflowService._stageNameGroupIdMap[_stageName];
            let groupLen = _groups.length;
            WorkflowService._menus.splice(groupCustomFormIndex, 1);
            let index = groupCustomFormIndex;
            for (let i = 0; i < groupLen; i++) {
                WorkflowService._menus.splice(index, 0, _groups[i]);
                index++;
            }
            groupCustomFormSize += groupLen - 1;
        }
        let hiddenTabIndex = -1;
        if (WorkflowService._groupIdsToHide.length > 0) {
            let _groupDisplayId = WorkflowService._groupIdsToHide[0];
            hiddenTabIndex = WorkflowService._menus.indexOf(_groupDisplayId);
        }

        let currentNotCompletedStage = -1;
        let groupStageIncr = 0;
        for (let i = 0; i < WorkflowService._allStagesState.length; i++) {
            let currentStageStatus = WorkflowService._allStagesState[i]['state']['stage_status'];
            let stageName = WorkflowService._allStagesState[i].name;

            if (currentStageStatus === "COMPLETED" ||
                currentStageStatus === "REVIEW_ADD_COMPLETED" ||
                currentStageStatus === "REVIEW_EDIT_COMPLETED") {
                if (groupCustomFormStages.indexOf(stageName) !== -1) {
                    let _groups = WorkflowService._stageNameGroupIdMap[stageName];
                    let groupLen = _groups.length;
                    for (let j = 0; j < groupLen; j++) {
                        WorkflowService._allCompletedStagesState.push(_groups[j]);
                    }
                    groupStageIncr += (groupLen - 1);
                } else {
                    WorkflowService._allCompletedStagesState.push(WorkflowService._allStagesState[i]['name']);
                }
            } else {
                if (currentNotCompletedStage < 0 ) {
                    if(groupCustomFormStages.indexOf(stageName) !== -1){
                        let currentGroup = WorkflowService._allStagesState[i]['state']['current_group'];
                        let currentGroupStatus = WorkflowService._allStagesState[i]['state']['group_status'];
                        if(!!currentGroup && !!currentGroupStatus){
                            let _groups = WorkflowService._stageNameGroupIdMap[stageName];
                            let groupLen = _groups.length;
                            for (let j = 0; j < groupLen; j++) {
                                if(_groups[j] === this.getGroupDisplayId(currentGroup)){
                                    break;
                                }
                                WorkflowService._allCompletedStagesState.push(_groups[j]);
                                groupStageIncr++;
                            }
                            if (currentGroupStatus === "COMPLETED" ) {
                                WorkflowService._allCompletedStagesState.push(this.getGroupDisplayId(currentGroup));
                                groupStageIncr++;
                            }
                        }

                    }
                    currentNotCompletedStage = i + groupStageIncr;

                    if (hiddenTabIndex != -1 && currentNotCompletedStage >= hiddenTabIndex) {
                        let groupIdsToHide = WorkflowService._groupIdsToHide;
                        let groupLen = groupIdsToHide.length;
                        for (let i = 0; i < groupLen; i++) {
                            let _groupDisplayId = groupIdsToHide[i];
                            let index = WorkflowService._menus.indexOf(_groupDisplayId);
                            if (currentNotCompletedStage === index) {
                                currentNotCompletedStage = -1;
                                break;
                            }
                        }
                    }
                }
            }

            // console.log("all completed menu", WorkflowService._allCompletedStagesState);
        }



        if (groupCustomFormStages.indexOf(WorkflowService._currentStage) !== -1) {
            let _groups = WorkflowService._stageNameGroupIdMap[WorkflowService._currentStage];
            let groupCustomFormSize = _groups.length;
            if (groupCustomFormSize > 0) {
                for (let i = 0; i < groupCustomFormSize; i++) {
                    let groupDisplayId = WorkflowService._groups[i];
                    if (WorkflowService._currentGroup &&
                        this.getGroupDisplayId(WorkflowService._currentGroup) === groupDisplayId) {
                        WorkflowService._currentStage = groupDisplayId;
                        WorkflowService._currentStageStatus = WorkflowService._currentGroupStatus;
                        break;
                    } else if (i === 0) {
                        WorkflowService._currentStage = groupDisplayId;
                        WorkflowService._currentStageStatus = WorkflowService._currentGroupStatus;
                    }
                }
            }
        }

        /*
        * If Application gets current stage from workflow, then redirect user there else redirect
        * user to first stage first step
        *
        * NEED TO WORK ON THE BELOW LOGIC - because stage_status is newly introduced with step_status
        *
        */

        if (WorkflowService._currentStage == null) {
            if(!!WorkflowService._menuMap[this._stages[0]['name']]){
                this._setLandingURL = WorkflowService._menuMap[this._stages[0]['name']]['LINK'];
            }else {
                this._setLandingURL = WorkflowService._menuMap[WorkflowService._menus[0]]['LINK'];
            }
        } else {
            if (WorkflowService._currentStageStatus === 'COMPLETED') {
                let x = 0;
                x = WorkflowService._menus.indexOf(WorkflowService._currentStage);

                if (x < WorkflowService._menus.length - 1) {
                    this._setLandingURL = WorkflowService._menuMap[WorkflowService._menus[currentNotCompletedStage]]['LINK'];
                } else if (x === WorkflowService._menus.length - 1) {
                    this._setLandingURL = '/dashboard';
                    //  this._setLandingURL = WorkflowService._menuMap[WorkflowService._menus[x]]['LINK'];
                }

                /****PA-1083****/
                // if(WorkflowService._currentStageStatus === 'PENDING'){
                //   this._setLandingURL = WorkflowService._menuMap[WorkflowService._menus[x]]['LINK'];
                // }
                /*********end***/
                // this._setLandingURL = WorkflowService._menuMap[WorkflowService._menus[x+1]]['LINK'];
            } else if (WorkflowService._currentStageStatus === 'REVIEW_ADD_COMPLETED' || WorkflowService._currentStageStatus === 'REVIEW_EDIT_COMPLETED') {
                this._setLandingURL = '/home/review/show-all';
            } else {
                let x = 0;
                x = WorkflowService._menus.indexOf(WorkflowService._currentStage);
                this._setLandingURL = WorkflowService._menuMap[WorkflowService._menus[x]]['LINK'];
            }
        }

        // assign lock level to the static variable in workflow service for ATS
        if (!!response['lock_level']) {
            WorkflowService._lockLevel = response['lock_level'];
        }

        if (!!response['BANDWIDTH_MEASURE_URL']) {
            WorkflowService._bandWidthCheckUrl = response['BANDWIDTH_MEASURE_URL'];
        }

        return {
            'URL': this._setLandingURL
        };
    }

    private processNextDetails(currentStage: string, key: string): string {
        let _value;
        let index = (WorkflowService._menus && WorkflowService._menus.indexOf(currentStage));

        if (WorkflowService._menus && index < WorkflowService._menus.length - 1) {

            let menu = WorkflowService._menus[index + 1];
            do { // Iterate every next item if it is in hidden group.
                var foundIndex =  WorkflowService._groupIdsToHide.indexOf(menu);
                if (foundIndex != -1) {
                    //ignore the hidden menuItem
                    index += 1;
                    menu = WorkflowService._menus[index + 1];
                }
            } while(foundIndex != -1)
            if (!!WorkflowService._menuMap[WorkflowService._menus[index + 1]])
            _value = WorkflowService._menuMap[WorkflowService._menus[index + 1]][key];
        } else {
            _value = '/dashboard';
        }
        // Remove <br/> from the display name custom forms
        if (key == 'DISPLAY_NAME') {
            _value = _value.replace(/<br\/>/g, '');
        }

        return _value;
    }

    getNextURL(currentStage: string): string {
        return this.processNextDetails(currentStage, 'LINK');
    }

    getNextMenuDisplayName(currentStage: string) {
        return this.processNextDetails(currentStage, 'DISPLAY_NAME');
    }

    getNextMenuStageName(currentStage: string) {
        return this.processNextDetails(currentStage, 'displayNameId');
    }

    private _handleError(eventName, error: any) {
        let _body = error || {};
        let errorMessage: string;

        console['server'](eventName, error);

        switch (error.status) {
            case 400:
            case 401:
                //Bad request, Invalid Credentials - login invalid
                errorMessage = 'Invalid Something';
                break;

            case 404:
                break;
        }
        return throwError(errorMessage);
    }

    getSteps(stepName: string): any {
        return  WorkflowService._steps && WorkflowService._steps[stepName];
    }

    /* Arguments - Stage Name, Step index*/
    getStepConfig(stageName: string, index: number): Object {
        let stepObject;

        // Check if Stage is available
        if (WorkflowService._steps != undefined && WorkflowService._steps[stageName]) {
            stepObject = WorkflowService._steps[stageName][index];

        } else {
            stepObject = {
                'stageNotPresent': true
            }
        }

        return stepObject;
    }

    // WOTCENABLE CHECK -- START
    getWOtcStatus(): boolean {
        return WorkflowService.isWotcEnable;
    }  // WOTC ENABLE CHECK -- END

    //
    getMenu(): any {
        return WorkflowService._menus;
    }

    getMenuLink(stageName: string): string {
        return AppConfig.getMenuMap()[stageName]['LINK'];
    }

    getCurrentStage(): string {
        return WorkflowService._currentStage;
    }

    getCurrentStep(): string {
        return WorkflowService._currentStep;
    }

    getCurrentStatus(): string {
        return WorkflowService._currentStatus;
    }

    getStageStatus(): string {
        return WorkflowService._currentStageStatus;
    }

    getMenuCount(): number {
        return WorkflowService._menus.length;
    }

    getCurrentSubStep(): string {
        return WorkflowService._currentSubStep;
    }

    getCurrentSubStepIndex(): string {
        return WorkflowService._currentSubStepIndex;
    }

    getCurrentIteration(): string {
        return WorkflowService._currentIteration;
    }

    getAllCompletedStageMenu(): any {
        return WorkflowService._allCompletedStagesState;
    }

    setJWTToken(token: string) {
        WorkflowService._JWTToken = token;
    }

    getJWTToken(): string {
        return WorkflowService._JWTToken;
    }

    getGroupCustomForms(): any {
        return WorkflowService._groups;
    }

    getGroupIdNameMap(): any {
        return WorkflowService._groupIdNameMap;
    }

    getStageNameGroupIdMap(): any {
        return WorkflowService._stageNameGroupIdMap;
    }

    getGroupNameFormIdMap(): any {
        return WorkflowService._groupNameFormIdMap;
    }

    getHeaderOptions(): any {
        const authHeaders = new HttpHeaders({
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Authorization': 'Bearer ' + this.localStorage.getItem('access_token')
        });

        let test = this.localStorage.getItem('access_token')

        const OPTIONS = { headers: authHeaders };
        WorkflowService.options = OPTIONS;

        return OPTIONS;
    }


  getOIDCHeaderOptionsWithTraces(traces: any) {
      return this.getHeaderOptionsWithTraces(traces);
/*
      let authHeaders: HttpHeaders = this.getHeaderOptionsWithTraces(traces);
      authHeaders.set("Content-Type","application/json");
      authHeader.set("Accept","application/json");
      return authHeaders;
*/
  }

    getHeaderOptionsWithTraces(traces: any) {

      let action = traces['action'];

      const authHeaders = new HttpHeaders({
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Authorization': 'Bearer ' + this.localStorage.getItem('access_token'),
        'x-pa-action': action,
        'x-pa-session-id': !!this.localStorage.getItem('access_token') ? this.localStorage.getItem('access_token') : '',
        'x-pa-user-id': !!this.localStorage.getItem('key') ?  this.localStorage.getItem('key') : ''
      });

      const OPTIONS = {headers: authHeaders};
      WorkflowService.options = OPTIONS;

      return OPTIONS;
    }


    getWotcConfig() {  // WOTC CONFIG
        let dynamicWotcData = WorkflowService.tempResponse;
        let workflowData = dynamicWotcData['workflow']['stages'];
        let tempData;
        let wotcData;

        for (let i = 0; i < workflowData.length; i++) {
            if ((workflowData[i]['name'] === 'wotc-data-v1')) {
                tempData = workflowData[i]['steps'];
                WorkflowService.workflowState = workflowData[i]['state'];

            }
        }
    }

    getDynamicCDConfig(currentStage?: string) {
        // Delete the below 3 lines and add response['workflow']['stages'] just return
        let dynamicCDData = WorkflowService.tempResponse;
        let workflowData = dynamicCDData['workflow']['stages']; // [5]['steps'][1]['forms'];
        let tempData;
        let customCDData;

        if (workflowData !== undefined) {
            for (let i = 0; i < workflowData.length; i++) {
                if (!!currentStage && (workflowData[i]['name'] === currentStage)) {
                    tempData = workflowData[i]['steps'];
                    WorkflowService.workflowState = workflowData[i]['state'];

                    for (let j = 0; j < tempData.length; j++) {
                        if (tempData[j]['name'] === 'consent-step-v1') {
                            customCDData = tempData[j]['forms'];
                        }
                    }
                }
            }
        }

        if (customCDData !== undefined) {
            for (let k = 0; k < customCDData.length; k++) {
                if (customCDData[k]['is_customconsent'] === false) {
                    WorkflowService.stdConsentDataConfig = customCDData[k];
                }
            }
        }

        WorkflowService.dynamicDataConfig = customCDData;
        return WorkflowService.dynamicDataConfig;
    }

    getESignatureConsentConfig(currentStage: string) {
        let dynamicCDData = WorkflowService.tempResponse;
        let workflowData = dynamicCDData['workflow']['stages']; // [5]['steps'][1]['forms'];
        let tempData;
        let eSigCDData;
        for (let i = 0; i < workflowData.length; i++) {
            if (!!currentStage && (workflowData[i]['name'] === currentStage)) {
                tempData = workflowData[i]['steps'];

                for (let j = 0; j < tempData.length; j++) {
                    if (tempData[j]['name'] === 'eauth-step-v1') {
                        eSigCDData = tempData[j]['forms'];
                    }
                }
            }
        }
        return eSigCDData;
    }
    getStdConsentConfig() {
        return WorkflowService.stdConsentDataConfig;
    }

    storeDocBaseUrl() {
        let responseData = WorkflowService.tempResponse;
        WorkflowService.docBaseURL = responseData['DOCBASE_URL'];
    }

    getDocBaseUrl(): string {
        return WorkflowService.docBaseURL;
    }

    // get LockLevel Value
    getLockLevelForATS() {
        return WorkflowService._lockLevel;
    }

    // get particular stage status -- EX:- allCompletedStagesState has all the component name which stage is completed
    getSpecificStageStatus(stageName: string) {
        return WorkflowService._allCompletedStagesState != undefined && WorkflowService._allCompletedStagesState.indexOf(stageName);
    }

    getGroupDisplayId(groupName) {
        let groupDisplayId = groupName;
        groupDisplayId = groupDisplayId.replace(/<br\/>/g, '');
        groupDisplayId = groupDisplayId.toUpperCase();
        groupDisplayId = JSON.parse(JSON.stringify(groupDisplayId));
        groupDisplayId = groupDisplayId.split(' ').join('');
        groupDisplayId= groupDisplayId.replace(/&/g, '-');
        groupDisplayId = groupDisplayId.replace(/\(/g, '-');
        groupDisplayId = groupDisplayId.replace(/\)/g, '-');
        return groupDisplayId;
    }

    getBandwidthCheckUrl() {
        return WorkflowService._bandWidthCheckUrl;
    }

    static setAuthType(value: string) {
        WorkflowService.authType = value;
    }

    getAuthType() {
        return WorkflowService.authType;
    }

    showOrHideAFPTab(isAFPEnabled: boolean) {
        if (isAFPEnabled !== undefined) {
            let groupDisplayId = WorkflowService.AFPCONSENT;
            let id = WorkflowService._groupNameFormIdMap[groupDisplayId];
            if (!!id) {
                let index = WorkflowService._groupIdsToHide.indexOf(groupDisplayId);
                if (!!isAFPEnabled) {
                    if (index != -1) {
                        WorkflowService._groupIdsToHide.splice(index, 1);
                    }
                    jQuery("#" + id).removeClass("hidden");
                    jQuery("#" + id).addClass("show");
                } else {
                    jQuery("#" + id).removeClass("show");
                    jQuery("#" + id).addClass("hidden");
                    if (index == -1) {
                        WorkflowService._groupIdsToHide.push(groupDisplayId);
                    }
                }
            }
        }
    }

    showOrHideNVNPTab(isNVNPEnabled: boolean) {
        try {
            if (isNVNPEnabled !== undefined) {
                let groupDisplayId = WorkflowService.NVNPCONSENT;
                let id = WorkflowService._groupNameFormIdMap[groupDisplayId];
                if (!!id) {
                    let index = WorkflowService._groupIdsToHide.indexOf(groupDisplayId);
                    if (!!isNVNPEnabled) {
                        if (index != -1) {
                            WorkflowService._groupIdsToHide.splice(index, 1);
                        }
                        let menuAtags = jQuery('#navigationMenuBar').find('a');
                        if (menuAtags && menuAtags != null && menuAtags.length>0){
                            menuAtags.each(function(){
                                var o = jQuery(this);
                                if (!!o.data('display-name-id') && o.data('display-name-id') == id){
                                    jQuery(o).removeClass("hidden");
                                    jQuery(o).addClass("show");
                                }
                            });
                        }
                    } else {
                        let menuAtags = jQuery('#navigationMenuBar').find('a');
                        if (menuAtags && menuAtags != null && menuAtags.length>0){
                            menuAtags.each(function(){
                                var o = jQuery(this);
                                if (!!o.data('display-name-id') && o.data('display-name-id') == id){
                                    jQuery(o).removeClass("show");
                                    jQuery(o).addClass("hidden");
                                }
                            });
                        }
                        if (index == -1) {
                            WorkflowService._groupIdsToHide.push(groupDisplayId);
                        }
                    }
                }
            }
        } catch(e){
            console.error('error in showOrHideNVNPTab', e);
        }
    }

    showOrHideCustomFormTab(isFormEnabled: boolean, formDisplayId : string) {
        try {
            if (isFormEnabled !== undefined) {
                let groupDisplayId = formDisplayId;
                let id = WorkflowService._groupNameFormIdMap[groupDisplayId];
                if (!!id) {
                    let index = WorkflowService._groupIdsToHide.indexOf(groupDisplayId);
                    if (!!isFormEnabled) {
                        if (index != -1) {
                            WorkflowService._groupIdsToHide.splice(index, 1);
                        }
                        let menuAtags = jQuery('#navigationMenuBar').find('a');
                        if (menuAtags && menuAtags != null && menuAtags.length>0){
                            menuAtags.each(function(){
                                var o = jQuery(this);
                                if (!!o.data('display-name-id') && o.data('display-name-id') == id){
                                    jQuery(o).removeClass("hidden");
                                    jQuery(o).addClass("show");
                                }
                            });
                        }
                    } else {
                        let menuAtags = jQuery('#navigationMenuBar').find('a');
                        if (menuAtags && menuAtags != null && menuAtags.length>0){
                            menuAtags.each(function(){
                                var o = jQuery(this);
                                if (!!o.data('display-name-id') && o.data('display-name-id') == id){
                                    jQuery(o).removeClass("show");
                                    jQuery(o).addClass("hidden");
                                }
                            });
                        }
                        if (index == -1) {
                            WorkflowService._groupIdsToHide.push(groupDisplayId);
                        }
                    }
                }
            }
        } catch(e){
            console.error('error in showOrHideNVNPTab', e);
        }
    }

    getDisableQnBtnHighlight() {
        return WorkflowService._disableQnBtnHighlight;
    }
    
    getHeaderOptionsForAzure(): any {
        const authHeaders = new HttpHeaders({
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Authorization': 'Bearer ' + this.localStorage.getItem('access_token'),
            'api-key': environment.cognitive_search_key
        });

        const OPTIONS = { headers: authHeaders };
        WorkflowService.options = OPTIONS;

        return OPTIONS;
    }
    
    checkStageStatus(stageNameValue): string {
        let status = '';
        
        for (let i = 0; i < WorkflowService._allStagesState.length; i++) {
            let stageName = WorkflowService._allStagesState[i]['name'];
            
            if (stageNameValue === stageName) {
                status = !!WorkflowService._allStagesState[i]['state'] ? WorkflowService._allStagesState[i]['state']['stage_status'] : '';
            }
        }
        
        return status;
    }
    
    // Loop through workflow response, identify the stages. Loop through the stages and check with stagename rightid-data-v1 
    // and step rightid-step-v1 and pick the prefernce - is_switch_flow from the object and return
    isRIDPREComponent(): boolean {
        let workflowResponse = WorkflowService.tempResponse;
        let workflowStages = workflowResponse['workflow']['stages'];
        let isRIDPREComponent = false;
        
        for (let i = 0; i < workflowStages.length; i++) {
            let stageName = workflowStages[i]['name'];
            let steps = workflowStages[i]['steps']; 
            
            if (stageName === 'rightid-data-v1') {
                for (let j = 0; j < steps.length; j++) {
                    if (steps[j]['name'] === 'rightid-step-v1') {
                        let preferences = !!steps[j]['preferences'] ? steps[j]['preferences'] : '';
                        if (!!preferences && !!preferences['is_switch_flow']) {
                            isRIDPREComponent = preferences['is_switch_flow'];
                        }
                    }
                }
            }
        }
        
        return isRIDPREComponent;
    }
}
