<div class="pad_30" [formGroup]="empSubForm">
  <!--<button type="button" class="btn arrowrightctr" debounceDirective (debounceClick)="verificationConfirm(empSubForm.value)"> <i class="icon-arrow-right"   ></i></button>-->
  <button type="button" class="btn arrowleftctr" attr.data-id="empVerifyConfirmLeftArrow_{{row}}" debounceDirective
    (click)="getPrevSwipe($event)"><i
      class="icon-arrow-left" role="img" [attr.aria-label]="'SCREEN_BACKWARD_ARROW' | translate"></i></button>
  <div class="anime-clean citadelContentArea contetnheight posrel">
    <div class="row no-form-element">
      <div class="col-md-12 col-xs-12 col-sm-12">
        <div class="header_txt_white">
          <h2>{{ 'EMP_CITADEL_DESCRIPTION' | translate }}</h2>
          <div [innerHTML]="citadelExplainerContent"></div>
        </div>
      </div>
    </div>

    <div *ngIf="showCitadelData" class="row m-t-40">
      <div id="container" class="m-b-20">
        <div class="contents">
          <div class="inlinecardcss adjustCard">
            <div class="addCard">
              <div class="dashboardCard maarright15 atsselect">
                <img src="../../assets/img/briefcase.png" height="50px">
                <p class=" boldcom boldtext_ellipses">{{empSubForm.controls.companyName.value}}</p>
                <span class="boldcom margtop10">{{empSubForm.controls.jobTitle.value}}</span>
                <p class="duration m-b-10">{{empSubForm.controls.jobFrom.value | timeStampConvert :dateFormat }} - {{
                  'EMPLOYMENT_PRESENT' | translate}} </p>  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row fixedbtn">
      <div *ngIf="!showCitadelData" class="col-xs-12 col-sm-4 col-md-3 fixedbtnb">
        <button type="button" class="btn button grow font_extrabold" attr.data-id="payrollLookupConfirm_{{row}}"
          debounceDirective (click)="payrollLookup(empSubForm.value)">{{ 'EMP_CITADEL_NEXT_BTN'  | translate  }}</button>
      </div>
      <div *ngIf="showCitadelData" class="col-xs-12 col-sm-4 col-md-3 fixedbtnb">
        <button type="button" class="btn button grow font_extrabold" attr.data-id="payrollLookupConfirm_{{row}}"
          debounceDirective (click)="payrollLookup(empSubForm.value)">{{ 'COMMONS_NEXT'  | translate  }}</button>
      </div>
      <!-- 
      <div class="col-xs-12 col-sm-4 col-md-3 fixedbtnb">
        <button type="button" class="btn button_wbg grow font_extrabold" attr.data-id="empPayrollLookupOptOut_{{row}}"
          debounceDirective (click)="payrollOptOut(empSubForm.value)">{{ 'EMP_CITADEL_OPT_OUT_BTN'  | translate  }}</button>
      </div>
      -->
    </div>
    <div class="clearfix"></div>
  </div>
</div>