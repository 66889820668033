<div class="pad_30" [formGroup]="empSubForm">
  <div class="posrel">
    <div class="row">
      <div class="col-md-12 col-xs-12 col-sm-12">
        <div class="header_txt_white">

        </div>
      </div>
      <div class="row form-element-section">
        <div class="col-xs-12 col-sm-6 col-md-5 m-b-20">
          <div class="group">
            <!-- <label class="control-labelss" for="">{{'EMPLOYMENT_POSITION_NAME_LABLE'|translate }}</label> -->
            <label *ngIf="empType === 'current' && !showEndDate" class="control-labelss"
              for="employementPosition_{{row}}">{{'EMPLOYMENT_CURRENT_POSITION_HELD_LABLE' | translate}}</label>
            <label *ngIf="empType === 'current' && showEndDate" class="control-labelss"
              for="employementPosition_{{row}}">{{'EMPLOYMENT_POSITION_HELD_LABLE' | translate}}</label>
            <label *ngIf="empType === 'prior' && (currentRowPositionList  === undefined)" class="control-labelss"
              for="employementPosition_{{row}}">{{'EMPLOYMENT_LAST_POSITION_HELD_LABLE' | translate}}</label>
            <label *ngIf="empType === 'prior' && currentRowPositionList  !== undefined" class="control-labelss"
              for="employementPosition_{{row}}">{{'EMPLOYMENT_POSITION_HELD_LABLE' | translate}}</label>
            <input type="text" class="form-control" id="employementPosition_{{row}}" formControlName="empPosition" maxlength="50" aria-required="true"/>

          </div>
        </div>
      </div>
      <div class="row form-element-section">

        <div class="col-xs-12 col-sm-6 col-md-5 m-b-20">
          <div class="group">
            <label class="control-labelss" for="">{{(isEmploymentGapInDaysEnabled ? 'EMPLOYMENT_INTL_START_DATE_LABLE' : 'EMPLOYMENT_INTL_START_LABLE') |translate }}</label>
            <input type="hidden" class="form-control" name="intlStartDate" formControlName="intlStartDate"
              maxlength="50" />
            <pa-date-picker [attr.id]="'intlStartDateId'" [defaultDate]="" [minDate]="" [setDate]="startDate"
              picker="{{isEmploymentGapInDaysEnabled ? 'full-date' : 'month'}}" appear="bubble" theme="material" placeholder="" (ongetDpValue)="getFromDpValue($event)">
            </pa-date-picker>

            <div *ngIf="!isValidStartDate">
              <div class="validate custom-error error_new_ui">
                <div class="left_div">
                  <ul>
                    <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate }}</li>
                    <li *ngIf="startDateExist" class="message">{{ 'EMPLOYMENT_START_DATE_EXIST_ERROR' | translate }}
                    </li>
                  </ul>
                </div>
                <div class="right_div">
                  <div class="close" aria-hidden="true" debounceDirective (debounceClick)="closeStartDateError()">
                    <i class="icon-cancel"></i>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-5 m-b-20">
          <div class="group">
            <div *ngIf="showEndDate || empType === 'prior'">
              <label class="control-labelss" for="">{{(isEmploymentGapInDaysEnabled ? 'EMPLOYMENT_INTL_END_DATE_LABLE' : 'EMPLOYMENT_INTL_END_LABLE') |translate }}</label>
              <input type="hidden" class="form-control" name="intlEndDate" formControlName="intlEndDate"
                maxlength="50" />
              <pa-date-picker *ngIf="showEndDate || empType === 'prior'" attr.id="intlEndDateId" [defaultDate]=""
                [minDate]="" [setDate]="endDate" picker="{{isEmploymentGapInDaysEnabled ? 'full-date' : 'month'}}" appear="bubble" theme="material" placeholder=""
                (ongetDpValue)="getToDpValue($event)">
              </pa-date-picker>
            </div>
            <div *ngIf="empType === 'current' && !showEndDate">
              <label for="" class="m-t-10">{{'EMPLOYMENT_CURRENT_TROUGH_DATE_LABLE' | translate}}</label>
            </div>
            <div *ngIf="!isValidEndDate">
              <div class="validate custom-error error_new_ui">
                <div class="left_div">
                  <ul>
                    <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate }}</li>
                    <li *ngIf="endDateLessThanStartDate" class="message">
                      {{ 'EMPLOYMENT_END_DATE_LESS_THAN_START_DATE_ERROR' | translate }}</li>
                    <li *ngIf="endDateExist" class="message">{{ 'EMPLOYMENT_END_DATE_EXIST_ERROR' | translate }}</li>
                  </ul>
                </div>
                <div class="right_div">
                  <div class="close" aria-hidden="true" debounceDirective (debounceClick)="closeEndDateError()">
                    <i class="icon-cancel"></i>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>

      </div>

      <div class="row fixedbtn">
        <div class="col-xs-12 col-sm-4 col-md-4" *ngIf="editInd !== 'edit'">
          <button type="button" class="btn button font_extrabold senButton" attr.data-id="empMultiPositionAdd_{{row}}"
            [class.grow]="!isAddPositionDisabled" [disabled]="isAddPositionDisabled"
            debounceDirective (debounceClick)='addPosition(empSubForm.value)'>{{ 'MULTI_POSITION_ADD' | translate }}</button>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-4" *ngIf="editInd === 'edit'">
          <button type="button" class="btn button font_extrabold senButton" attr.data-id="empMultiPositionSave_{{row}}"
            [class.grow]="!isAddPositionDisabled" [disabled]="isAddPositionDisabled"
            debounceDirective (debounceClick)='editPosition(empSubForm.value)'>{{ 'MULTI_POSITION_EDIT' | translate }}</button>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>